var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detail-root" },
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            title: _vm.title,
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            width: "918px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            closed: _vm.onClosed,
          },
        },
        [
          _c("div", { staticClass: "content form-content" }, [
            _c("div", { staticClass: "row mutlti big" }, [
              _c("div", { staticClass: "tip" }, [
                _c("span", { staticClass: "point" }, [_vm._v("*")]),
                _vm._v("完税凭证金额"),
              ]),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      maxlength: "20",
                      placeholder: "请输入完税凭证金额",
                      disabled: _vm.isLook || _vm.isCheck,
                    },
                    model: {
                      value: _vm.formData.amount,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData,
                          "amount",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formData.amount",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row mutlti big" }, [
              _c("div", { staticClass: "tip" }, [
                _c("span", { staticClass: "point" }, [_vm._v("*")]),
                _vm._v("凭证"),
              ]),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("ossFileUpload", {
                    key: "1",
                    ref: "refUserFileUpload",
                    staticStyle: { "margin-bottom": "10px" },
                    attrs: {
                      limit: 3,
                      accept: _vm.picFileExt,
                      maxFileLength: 10,
                      ossUpload: true,
                      ossPathDir: "shop/pic/",
                      disabled: _vm.isLook || _vm.isCheck,
                    },
                    model: {
                      value: _vm.receipts,
                      callback: function ($$v) {
                        _vm.receipts = $$v
                      },
                      expression: "receipts",
                    },
                  }),
                  !_vm.isLook && !_vm.isCheck
                    ? _c("div", { staticClass: "small-tip warning" }, [
                        _c("span", [
                          _vm._v(
                            "请上传凭证。格式要求：pdf或图片，且不超过10M，最多上传3个。"
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm.isCheck
              ? _c("div", { staticClass: "row mutlti big" }, [
                  _c("div", { staticClass: "tip" }, [
                    _c("span", { staticClass: "point" }, [_vm._v("*")]),
                    _vm._v("审核结果"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "value input",
                      staticStyle: { "margin-top": "8px" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: !_vm.isCheck },
                          on: { change: _vm.handleAuditStatusChange },
                          model: {
                            value: _vm.formData.auditStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "auditStatus", $$v)
                            },
                            expression: "formData.auditStatus",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "3" } }, [
                            _vm._v("通过"),
                          ]),
                          _c("el-radio", { attrs: { label: "1" } }, [
                            _vm._v("驳回"),
                          ]),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "value-desc" }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.isCheck && _vm.formData.auditStatus === "1"
              ? _c("div", { staticClass: "row mutlti big" }, [
                  _c("div", { staticClass: "tip" }, [
                    _c("span", { staticClass: "point" }, [_vm._v("*")]),
                    _vm._v("驳回原因"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "value input",
                      staticStyle: { "margin-top": "8px" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          disabled: !_vm.isCheck,
                          placeholder: "请输入驳回原因",
                        },
                        model: {
                          value: _vm.formData.rejectReason,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "rejectReason", $$v)
                          },
                          expression: "formData.rejectReason",
                        },
                      }),
                      _c("div", { staticClass: "value-desc" }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
          _c("OpsCheck", {
            attrs: {
              rejectHistory: _vm.memberInfo?.voucherRefusalList || [],
              auditStatusPass: "2, 3",
              auditRejectKey: "reason",
              auditStatusKey: "status",
              createName: "auditUser",
              createTime: "auditDate",
            },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.close } },
                [_vm._v("取 消")]
              ),
              !_vm.isLook
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.handleSave },
                    },
                    [_vm._v("提交")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }