<template>
  <div class="detail-root">
    <el-dialog
      title="审核发票附件"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="600px"
      @closed="onClosed"
    >
      <div class="content form-content">
        <div class="row mutlti big">
          <div class="tip">发票金额</div>
          <div class="value input radio">
            <el-input
              v-model="formData.amount"
              style="width: 250px"
              :disabled="true"
            ></el-input>
          </div>
        </div>
        <div class="row mutlti big">
          <div class="tip">附件</div>
          <div class="value input radio">
            <ossFileUpload
              ref="refPicFileUpload"
              v-model="formData.picFileList"
              :limit="1"
              :accept="picFileExt"
              :maxFileLength="10"
              :ossUpload="true"
              :ossPathDir="'shop/pic/'"
              style="margin-bottom: 10px"
              :disabled="true"
              listType="text"
            >
            </ossFileUpload>
          </div>
        </div>
        <div class="row mutlti big">
          <div class="tip">审核结果</div>
          <div class="value input radio">
            <el-radio-group v-model="formData.radio">
              <el-radio :label="2">通过</el-radio>
              <el-radio :label="3">驳回</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="row mutlti big" v-if="formData.radio == 3">
          <div class="tip">驳回理由</div>
          <div class="value input">
            <el-input
              size="small"
              type="textarea"
              maxlength="20"
              v-model="formData.desc"
              placeholder="请输入驳回理由"
              :autosize="{ minRows: 2, maxRows: 4 }"
            ></el-input>
          </div>
        </div>
        <div class="row mutlti big">
          <div class="tip">审核历史</div>
          <div class="value input" style="margin-top: 8px">
            <el-timeline :reverse="false">
              <el-timeline-item
                v-for="(activity, index) in rejectHistory"
                :key="index"
                :timestamp="activity.createTime"
              >
                <div>
                  <span>【{{ activity.createBy }}】</span>
                  <span>{{ activity.state === 1 ? "通过" : "驳回：" }}</span>
                  <span>{{ activity.reason || "" }}</span>
                </div>
              </el-timeline-item>
            </el-timeline>
            <div class="value-desc"></div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="close">取 消</el-button>
        <el-button size="small" type="primary" @click="handleSave"
          >提交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import { csoMemberAdd, csoMemberEdit } from "@/api/complianceCso/salesman";
import {
  contractTargetAudit,
  contractTargetInvoiceV2ById,
} from "@/api/compliance/invoiceManager";
import ossFileUpload from "@/components/ossFileUpload";
import { getOssSrcFileName } from "@/utils/oss";
export default {
  components: { ossFileUpload },
  data() {
    return {
      dialogVisible: false,
      mainId: null,
      formData: {
        desc: "",
        radio: 2,
        amount: "",
        picFileList: [],
      },
      rejectHistory: [],
      picFileExt: ".jpg,.png,.pdf,.jpeg",
    };
  },
  computed: {},
  methods: {
    open(row = null) {
      if (row) {
        this.mainId = row.id;
        this.dialogVisible = true;
        this.getContractDetail();
      }
    },
    getContractDetail() {
      contractTargetInvoiceV2ById(this.mainId).then((res) => {
        if (res) {
          const { voucherPrice, voucherUrl, voucherRefusalList } = res;
          this.formData.amount = voucherPrice;
          this.formData.picFileList = [this.parseToUploadUrl(voucherUrl)];
          this.rejectHistory = voucherRefusalList || [];
        }
      });
    },
    parseToUploadUrl(newUrl) {
      if (newUrl) {
        const name = getOssSrcFileName(newUrl); //newUrl.split("/");
        return { name: name, url: newUrl };
      }
      return null;
    },
    close() {
      this.dialogVisible = false;
    },
    onClosed() {
      this.reset();
    },
    reset() {
      this.mainId = "";
      this.formData = {
        desc: "",
        radio: 2,
      };
    },
    handleSave() {
      const { radio, desc } = this.formData;
      if (radio == 3 && !desc) {
        this.$message.warning("驳回理由不能为空");
        return;
      }
      const data = {
        id: this.mainId,
        csoInvoiceAuditStatus: radio,
        csoInvoiceAuditIdea: radio == 3 ? desc : "",
      };
      contractTargetAudit(data).then((res) => {
        if (res) {
          this.$emit("onRefresh");
          this.dialogVisible = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-root {
  .form-content {
    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 0;
      box-sizing: border-box;

      &.mutlti {
        align-items: flex-start;
      }

      &.big {
        .tip {
          height: 32px;
          line-height: 32px;
        }
      }

      .tip {
        width: auto;
        font-size: 15px;
        font-weight: 400;
        text-align: right;
        color: #262626;
        width: 100px;
      }

      .value {
        margin-left: 16px;

        .warntxt {
          display: flex;
          height: 32px;
          align-items: center;
          justify-content: flex-start;
          font-size: 16px;
          color: var(--main-color);
          margin-left: 24px;
        }

        &.input {
          width: calc(100% - 120px);
        }

        &.radio {
          min-height: 32px;
          display: flex;
          align-items: center;
        }
        ::v-deep .el-upload-list__item-name {
          width: 400px;
          padding: 0px;
        }
      }
    }
  }

  ::v-deep .el-dialog__body {
    max-height: 60vh;
    overflow-y: auto;
    min-height: 200px;
  }
}
</style>
