<template>
  <div class="classification">
    <div class="header">
      <el-form
        ref="formTool"
        :model="formTool"
        :inline="true"
        @submit.native.prevent
        style="text-align: left"
      >
        <el-form-item prop="contractName" label="">
          <el-input
            style="width: 180px"
            v-model="formTool.contractName"
            placeholder="请搜索合同名称"
            clearable
            size="small"
          />
        </el-form-item>
        <el-form-item prop="draftShopName" label="">
          <el-input
            style="width: 180px"
            v-model="formTool.draftShopName"
            placeholder="请搜索医药公司名称"
            clearable
            size="small"
          />
        </el-form-item>
        <el-form-item prop="name" label="">
          <el-input
            style="width: 180px"
            v-model="formTool.name"
            placeholder="请搜索指标名称"
            clearable
            size="small"
          />
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="getList(1)"
            >查询</el-button
          >
          <el-button size="small" @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
      <div>
        <!-- <el-button size="small" type="primary" plain @click="goExport">导出</el-button> -->
        <!-- <el-button size="small" type="primary" @click="openOpDetail()">新建</el-button> -->
      </div>
    </div>
    <div class="center" style="position: relative">
      <MyTable
        :index="false"
        :table-data="tableData"
        :page="page"
        :table-option.sync="tableOption"
        @page-change="tablePageChange"
      >
        <template slot="csoInvoiceAuditStatus" slot-scope="scope">
          <div>
            {{ scope.row.csoInvoiceStatus | statusTxt(statusData) }}
          </div>
        </template>
        <template slot="createTime" slot-scope="scope">
          <div
            v-if="scope.row.createTime"
            style="display: flex; flex-direction: column"
          >
            <span v-for="(li, i) in scope.row.createTime.split(' ')" :key="i">{{
              li
            }}</span>
          </div>
        </template>
        <template slot="tools" slot-scope="scope">
          <div class="table-op-col">
            <!-- <strong>发票PDF地址：</strong> <a class="expand_li_link" :href="props.row.fileUrl" target="_blank">查看文件</a> -->
            <el-link
              type="primary"
              :underline="false"
              size="small"
              v-if="
                scope.row.csoInvoiceAuditStatus == 1 && scope.row.csoInvoiceUrl
              "
              @click="openLink(scope.row.csoInvoiceUrl)"
              >查看发票附件</el-link
            >
            <el-link
              type="primary"
              :underline="false"
              size="small"
              v-if="scope.row.csoInvoiceAuditStatus == 1"
              @click="openOpDetail(scope.row)"
              >审核</el-link
            >
            <el-link
              type="primary"
              :underline="false"
              size="small"
              v-if="
                scope.row.csoInvoiceAuditStatus == 2 && scope.row.csoInvoiceUrl
              "
              @click="openLink(scope.row.csoInvoiceUrl)"
              >查看发票</el-link
            >
            <!-- <el-link type="primary" :underline="false" size="small" @click="openOpDetail(2, scope.row)">驳回</el-link> -->
          </div>
        </template>
      </MyTable>
    </div>
    <OpDetail ref="opDetailRef" @onRefresh="handleRefresh" />
  </div>
</template>

<script>
import MyTable from "@/components/myTable/index.vue";
import OpDetail from "./dialog/op-detail.vue";
import { contractTargetPage } from "@/api/compliance/invoiceManager";

export default {
  components: {
    MyTable,
    OpDetail,
  },
  data() {
    return {
      formTool: {
        contractName: "",
        draftShopName: "",
        name: "",
      },
      tableData: [],
      tableOption: [
        { label: "合同名称", prop: "contractName" },
        { label: "医药公司", prop: "draftShopName" },
        { label: "指标名称", prop: "name" },
        { label: "任务名称", prop: "targetTaskName" },
        { label: "任务金额", prop: "targetTaskPrice" },
        { label: "税率/征收率", prop: "csoTaxRate" },
        { label: "税额", prop: "csoTaxAmount" },
        { label: "价税合计", prop: "csoTotalAmount" },
        { label: "状态", prop: "csoInvoiceAuditStatus", slot: true },
        { label: "创建人", prop: "createBy" },
        { label: "创建时间", prop: "createTime", width: "100px", slot: true },
        { label: "操作", prop: "tools", slot: true, width: "150px" },
      ],
      page: { total: 0, current: 1, size: 10 },
      // 开票状态: 0. 待开票 ,1. 开票中，2. 开票完成，3. 开票失败
      statusData: [
        { label: "待开票", value: 0 },
        { label: "开票中", value: 1 },
        { label: "开票成功", value: 2 },
        { label: "开票失败", value: 3 },
      ],
    };
  },
  filters: {
    statusTxt: (val, options = []) => {
      let item = options.find((li) => li.value == val);
      // console.log(item, options, val)
      return item ? item.label : "待开票";
    },
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    openOpDetail(row = null) {
      this.$refs.opDetailRef.open(row);
    },
    getList(num) {
      if (num) {
        this.page.current = num;
      }
      const { current, size } = this.page;
      let params = { current, size, ...this.formTool };
      this.listLoading = true;
      contractTargetPage(params)
        .then((res) => {
          this.tableData = res.records;
          this.page.total = res.total;
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    handleRefresh() {
      this.getList(1);
    },
    tablePageChange() {
      this.getList();
    },
    handleReset() {
      for (let key in this.formTool) {
        this.formTool[key] = "";
      }
      this.getList(1);
    },
    openLink(url = null) {
      if (!url) {
        this.$message.warning("跳转链接为空，请确认后重试");
        return;
      }
      console.log("跳转链接为：", url);
      // let testUrl = 'https://www.baidu.com/s?wd=月光';
      window.open(url); // 打开外部地址
    },
    // goExport() {
    //   csoMemberExport({}).then(res => {
    //     console.log('导出信息成功', res);
    //   })
    // },
  },
};
</script>

<style lang="scss" scoped>
.classification {
  padding: 0px;
  background: white;

  .header {
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px 0px 24px !important;
    border-bottom: 1px solid #f5f5f5;

    .el-form-item {
      margin: 0px 0px 0px 8px !important;
    }
  }

  .center {
    margin-top: 16px;
    padding: 0 24px;
    height: calc(100% - 131px);
  }

  .table-op-col {
    display: flex;
    align-items: center;

    .el-link {
      & + .el-link {
        margin-left: 8px;
      }
    }
  }
}
</style>
