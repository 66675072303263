var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tabs-container" },
    [
      _vm.showTabs
        ? _c(
            "el-tabs",
            {
              attrs: { "before-leave": _vm.beforeLeave },
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.getActiveName,
                callback: function ($$v) {
                  _vm.getActiveName = $$v
                },
                expression: "getActiveName",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: { label: "业务员管理", name: "first" },
              }),
              _c("el-tab-pane", {
                attrs: { label: "人员资质管理", name: "second" },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }