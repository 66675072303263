<template>
  <div class="classification">
    <div class="header">
      <el-form
        ref="formTool"
        :model="formTool"
        :inline="true"
        @submit.native.prevent
        style="text-align: left"
      >
        <el-form-item prop="contractName" label="">
          <el-input
            style="width: 180px"
            v-model="formTool.contractName"
            placeholder="请搜索合同名称"
            clearable
            size="small"
          />
        </el-form-item>
        <el-form-item prop="draftShopName" label="">
          <el-input
            style="width: 180px"
            v-model="formTool.draftShopName"
            placeholder="请搜索医药公司名称"
            clearable
            size="small"
          />
        </el-form-item>
        <el-form-item prop="name" label="">
          <el-input
            style="width: 180px"
            v-model="formTool.name"
            placeholder="请搜索指标名称"
            clearable
            size="small"
          />
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="getList(1)"
            >查询</el-button
          >
          <el-button size="small" @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
      <div>
        <el-button size="small" type="primary" @click="handleOpenDigital"
          >开通数电</el-button
        >
        <el-button size="small" type="primary" @click="handleTaxRateSetting"
          >税率设置</el-button
        >
        <el-button size="small" type="primary" @click="handleInvoicing"
          >批量开票</el-button
        >
      </div>
    </div>
    <div class="center" style="position: relative">
      <MyTable
        :index="false"
        :table-data="tableData"
        :page="page"
        :table-option.sync="tableOption"
        @page-change="tablePageChange"
      >
        <template slot-scope="props" slot="csoInvoiceStatus">
          <span v-if="props.row.csoInvoiceStatus == 0">待开票</span>
          <span v-if="props.row.csoInvoiceStatus == 1">开票中</span>
          <span v-if="props.row.csoInvoiceStatus == 2">开票成功</span>
          <span v-if="props.row.csoInvoiceStatus == 3">开票失败</span>
        </template>
        <template slot="createTime" slot-scope="scope">
          <div
            v-if="scope.row.createTime"
            style="display: flex; flex-direction: column"
          >
            <span v-for="(li, i) in scope.row.createTime.split(' ')" :key="i">{{
              li
            }}</span>
          </div>
        </template>
        <template slot="tools" slot-scope="scope">
          <div class="table-op-col">
            <a
              class="expand_li_link"
              :href="scope.row.csoInvoiceUrl"
              target="_blank"
              v-if="scope.row.csoInvoiceStatus == 2 && scope.row.csoInvoiceUrl"
              >查看发票</a
            >
            <el-link
              type="primary"
              :underline="false"
              size="small"
              @click="handleUploadFile(scope.row)"
              v-if="
                scope.row.csoInvoiceStatus == 0 ||
                scope.row.csoInvoiceStatus == 3
              "
              >{{
                scope.row.csoInvoiceStatus == 0 ? "上传附件" : "重新上传附件"
              }}</el-link
            >
            <el-link
              v-if="scope.row.csoInvoiceStatus == 0"
              type="primary"
              :underline="false"
              size="small"
              @click="handleTaxCompanyInfo(scope.row)"
              >设置收票方信息</el-link
            >
            <el-link
              type="primary"
              :underline="false"
              size="small"
              @click="handleLookIdea(scope.row)"
              v-if="scope.row.csoInvoiceStatus == 3"
              >查看审核意见</el-link
            >
          </div>
        </template>
      </MyTable>
    </div>
    <OpDetail ref="opDetailRef" @onRefresh="handleRefresh" />
    <!-- 税率设置弹窗 -->
    <TaxRateSettingDialog ref="taxRateSettingDialogRef" />
    <!-- 税公司信息弹窗 -->
    <TaxCompanyInfoDialog
      ref="taxCompanyInfoDialogRef"
      @refresh="handleRefresh"
    />
    <!-- 上传附件弹窗 -->
    <UploadTaxFileDialog
      ref="uploadTaxFileDialogRef"
      @onRefresh="handleRefresh"
    />
    <!-- 开票弹窗 -->
    <InvoicingCSODialog ref="invoice-dailog" />
  </div>
</template>

<script>
import MyTable from "@/components/myTable/index.vue";
import OpDetail from "./dialog/op-detail.vue";
import TaxRateSettingDialog from "./dialog/tax-rate-setting-dialog.vue";
import TaxCompanyInfoDialog from "./dialog/tax-companyinfo-dialog.vue";
import UploadTaxFileDialog from "./dialog/upload-tax-file-dialog.vue";
import InvoicingCSODialog from "./components/invoicingStepsCSODialog.vue";
import {
  geCsotInvoiceList,
  getCsoTaxRate,
  contractTargetInvoiceV2ById,
} from "@/api/compliance/invoiceManager";
export default {
  components: {
    MyTable,
    OpDetail,
    TaxRateSettingDialog,
    TaxCompanyInfoDialog,
    UploadTaxFileDialog,
    InvoicingCSODialog,
  },
  data() {
    return {
      formTool: {
        contractName: "",
        draftShopName: "",
        name: "",
      },
      tableData: [],
      tableOption: [
        { label: "合同名称", prop: "contractName" },
        { label: "医药公司", prop: "draftShopName" },
        { label: "指标名称", prop: "name" },
        { label: "任务名称", prop: "targetTaskName" },
        { label: "任务金额", prop: "targetTaskPrice" },
        { label: "税率/征收率", prop: "csoTaxRate" },
        { label: "税额", prop: "csoTaxAmount" },
        { label: "价税合计", prop: "csoTotalAmount" },
        { label: "状态", prop: "csoInvoiceStatus", slot: true, width: "90px" },
        { label: "创建人", prop: "createBy", width: "120px" },
        { label: "创建时间", prop: "createTime", width: "100px", slot: true },
        { label: "操作", prop: "tools", slot: true, width: "150px" },
      ],
      page: { total: 0, current: 1, size: 10 },
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    openOpDetail(row = null) {
      this.$refs.opDetailRef.open(row);
    },
    getList(num) {
      if (num) {
        this.page.current = num;
      }
      const { current, size } = this.page;
      let params = { current, size, ...this.formTool };
      this.listLoading = true;
      geCsotInvoiceList(params)
        .then((res) => {
          this.tableData = res.records;
          this.page.total = res.total;
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    handleRefresh() {
      this.getList(1);
    },
    tablePageChange() {
      this.getList();
    },
    handleReset() {
      for (let key in this.formTool) {
        this.formTool[key] = "";
      }
      this.getList(1);
    },
    openLink(url = null) {
      if (!url) {
        this.$message.warning("跳转链接为空，请确认后重试");
        return;
      }
      console.log("跳转链接为：", url);
      // let testUrl = 'https://www.baidu.com/s?wd=月光';
      window.open(url); // 打开外部地址
    },
    // goExport() {
    //   csoMemberExport({}).then(res => {
    //     console.log('导出信息成功', res);
    //   })
    // },
    handleOpenDigital() {
      this.$router.push({
        path: "/invoiceCenter/invoiceCenter-invoiceInfoManege",
        query: {
          type: "add",
        },
      });
    },
    async handleTaxRateSetting() {
      const res = await getCsoTaxRate();
      if (res) {
        this.$refs.taxRateSettingDialogRef.init(res);
      } else {
        this.$refs.taxRateSettingDialogRef.init();
      }
    },
    handleTaxCompanyInfo(row) {
      this.$refs.taxCompanyInfoDialogRef.getInfo(row);
    },
    handleRefresh() {
      this.getList(1);
    },
    // 查看审核意见
    handleLookIdea(row) {
      contractTargetInvoiceV2ById(row.id).then((res) => {
        if (res) {
          const { voucherRefusalList } = res;
          if (voucherRefusalList && voucherRefusalList.length > 0) {
            const reason = voucherRefusalList[0].reason;
            this.$alert(reason, "驳回意见", "", {
              confirmButtonText: "确定",
              callback: (action) => {},
            });
          }
        }
      });
    },
    handleUploadFile(row) {
      this.$refs.uploadTaxFileDialogRef.publicOpen(row);
    },
    handleInvoicing() {
      this.$refs["invoice-dailog"].init();
    },
  },
};
</script>

<style lang="scss" scoped>
.classification {
  padding: 0px;
  background: white;

  .header {
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px 0px 24px !important;
    border-bottom: 1px solid #f5f5f5;

    .el-form-item {
      margin: 0px 0px 0px 8px !important;
    }
  }

  .center {
    margin-top: 16px;
    padding: 0 24px;
    height: calc(100% - 131px);
  }

  .table-op-col {
    display: flex;
    align-items: center;
    flex-direction: column;
    .expand_li_link {
      color: #f5222d;
    }
    .el-link {
      & + .el-link {
        margin-left: 8px;
      }
    }
  }
}
</style>
