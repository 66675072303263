import { render, staticRenderFns } from "./listDetail.vue?vue&type=template&id=2df1501c&scoped=true"
import script from "./listDetail.vue?vue&type=script&lang=js"
export * from "./listDetail.vue?vue&type=script&lang=js"
import style0 from "./listDetail.vue?vue&type=style&index=0&id=2df1501c&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2df1501c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/godata/pipelines/eShop-Portal-Web-Prod/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2df1501c')) {
      api.createRecord('2df1501c', component.options)
    } else {
      api.reload('2df1501c', component.options)
    }
    module.hot.accept("./listDetail.vue?vue&type=template&id=2df1501c&scoped=true", function () {
      api.rerender('2df1501c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/platformActivity/listDetail.vue"
export default component.exports