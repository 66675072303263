import { render, staticRenderFns } from "./divisionrule.vue?vue&type=template&id=700bea80&scoped=true"
import script from "./divisionrule.vue?vue&type=script&lang=js"
export * from "./divisionrule.vue?vue&type=script&lang=js"
import style0 from "./divisionrule.vue?vue&type=style&index=0&id=700bea80&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "700bea80",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/godata/pipelines/eShop-Portal-Web-Prod/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('700bea80')) {
      api.createRecord('700bea80', component.options)
    } else {
      api.reload('700bea80', component.options)
    }
    module.hot.accept("./divisionrule.vue?vue&type=template&id=700bea80&scoped=true", function () {
      api.rerender('700bea80', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/commissionmanagement/divisionrule.vue"
export default component.exports