<template>
  <div class="voucher-detail-root">
    <el-dialog
      class="voucher-dialog"
      :title="title"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="518px"
      @closed="onClosed"
      v-loading="loading"
    >
      <div class="content form-content">
        <div class="row big">
          <div class="tip">凭证名称</div>
          <div class="value input fill">
            <el-input
              size="small"
              maxlength="50"
              v-model="voucherName"
              :disabled="isDisabled"
              placeholder="请输入凭证名称"
            ></el-input>
          </div>
        </div>
        <div class="row big">
          <div class="tip">凭证金额</div>
          <div class="value input fill">
            <el-input
              size="small"
              type="number"
              maxlength="10"
              v-model.trim="voucherPrice"
              :disabled="isDisabled"
              placeholder="请输入凭证金额"
              @input="(e) => thresholdReg('voucherPrice', e)"
            ></el-input>
          </div>
        </div>
        <div class="row mutlti big">
          <div class="tip">附件</div>
          <div class="value input fill">
            <ossFileUpload
              ref="refPicFileUpload"
              v-model="picFileList"
              :limit="maxFileCount"
              :accept="picFileExt"
              :maxFileLength="10"
              :ossUpload="true"
              :ossPathDir="'shop/pic/'"
              style="margin-bottom: 10px"
              :disabled="isDisabled"
            >
            </ossFileUpload>
            <div class="small-tip warning" v-if="!isDisabled">
              <span
                >最多支持{{ maxFileCount }}张，单张不超过10M； 支持 .png .jpg
                .pdf</span
              >
            </div>
          </div>
        </div>
        <div class="row big small">
          <div class="tip"></div>
          <div class="value input fill">
            建议您使用康贝转账，平台支付工具便捷安全
          </div>
        </div>
        <div class="row big">
          <div class="tip">凭证类型</div>
          <div class="value input fill">
            <el-radio-group v-model="voucherType" :disabled="isDisabled">
              <el-radio :label="1">康贝转账</el-radio>
              <el-radio :label="2">其它</el-radio>
            </el-radio-group>
          </div>
        </div>
        <!-- 记录 -->
        <div
          class="rejected-layout"
          v-if="voucherRefusalList && voucherRefusalList.length > 0"
        >
          <div class="title">历史记录</div>
          <div class="v-list">
            <div
              class="v-list-item"
              v-for="(item, index) in voucherRefusalList"
              :key="index"
            >
              <div class="left">
                <div class="line up"></div>
                <div class="dot"></div>
                <div class="line down"></div>
              </div>
              <div class="datas-view" v-if="item.state == 0">
                <div class="reason">
                  【{{ item.createBy }}】驳回：{{ item.reason }}
                </div>
                <div class="time">{{ item.createTime }}</div>
              </div>
              <div class="datas-view" v-if="item.state == 1">
                <div class="reason">【{{ item.createBy }}】通过</div>
                <div class="time">{{ item.createTime }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="close">关闭</el-button>
        <el-button
          v-if="isUpdate"
          size="small"
          type="primary"
          @click="handleSave"
          >提交</el-button
        >
        <el-button
          v-if="isCheck"
          size="small"
          type="primary"
          @click="handleAuditOk"
          >通过</el-button
        >
        <el-button
          v-if="isCheck"
          size="small"
          type="primary"
          @click="handleRejust"
          >驳回</el-button
        >
      </div>
    </el-dialog>

    <rejustReason ref="refRejustReason" @onConfirm="handleAuditFail" />
  </div>
</template>

<script>
import ossFileUpload from "@/components/ossFileUpload";
import { splitMoney } from "@/utils/validate";
import rejustReason from "@/views/compliancecso/metricsManager/dialog/rejustReason.vue";

import {
  uploadingVoucher,
  updateVoucher,
  examineVoucher,
  contractTargetDetailV2,
} from "@/api/compliance/metricsManager";
import { getOssSrcFileName } from "@/utils/oss";

export default {
  components: { ossFileUpload, rejustReason },
  data() {
    return {
      action: 1, //1 新增  2 编辑 3 查看 4 审核
      dialogVisible: false,
      loading: false,

      id: "", //指标id
      voucherName: "",
      voucherPrice: "",
      voucherType: "",
      voucherRefusalList: [],

      maxFileCount: 9,
      /**
         * {
            fileName: "check_2024060511154657176078.png",
            name: "check_2024060511154657176078.png",
            url: "https://eshop-develop.oss-cn-hangzhou.aliyuncs.com/goods/check_2024060511154657176078.png",
          },
         */
      picFileList: [],
      picFileExt: " .png, .jpg, .pdf,.jpeg",
    };
  },
  computed: {
    title() {
      let txt = "凭证";
      if (this.action === 2) {
        txt = `编辑${txt}`;
      } else if (this.action === 3) {
        txt = `查看${txt}`;
      } else if (this.action === 4) {
        txt = `审核${txt}`;
      } else {
        txt = `上传${txt}`;
      }
      return txt;
    },
    isDisabled() {
      return this.isLook || this.isCheck;
    },
    isLook() {
      return this.action === 3;
    },
    //是不是创建或编辑
    isUpdate() {
      return this.action === 1 || this.action === 2;
    },
    isCheck() {
      return this.action === 4;
    },
    showAuditOpinion() {
      return this.isLook && this.auditStatus === 2;
    },
  },
  methods: {
    open(options = null) {
      if (options) {
        this.action = options.action ? options.action : 1;
        this.id = options.id;
        if (this.action !== 1) {
          this.getDetail();
        }
      }

      this.dialogVisible = true;

      this.$nextTick(() => {
        if (this.isUpdate) {
          this.$refs.refPicFileUpload.init(); //初始化oss上传
        }
      });
    },
    close() {
      this.dialogVisible = false;
    },
    onClosed() {
      this.reset();
    },
    reset() {
      this.action = 1;
      this.voucherName = "";
      this.voucherPrice = "";
      this.voucherType = "";
      this.voucherRefusalList = [];
      this.picFileList = [];
      this.$refs.refPicFileUpload.release();
      this.loading = false;
    },
    thresholdReg(key, value) {
      // 9999999.99
      if (value) {
        let reg = /^\d{1,7}(\.\d{0,2})?$/;
        if (!reg.test(value)) {
          this[key] = splitMoney(value, 7, 2);
        }
      }
    },
    getDetail() {
      if (this.id) {
        this.loading = true;
        contractTargetDetailV2(this.id)
          .then((data) => {
            this.loading = false;
            if (data) {
              this.voucherName = data.voucherName;
              this.voucherPrice = data.voucherPrice;
              this.voucherType = data.voucherType ? +data.voucherType : "";
              if (data.voucherUrl) {
                const urls = data.voucherUrl.split(",");
                this.picFileList = urls.map((item) => {
                  return this.parseToUploadUrl(item);
                });
              }

              this.voucherRefusalList = Array.isArray(data.voucherRefusalList)
                ? data.voucherRefusalList
                : [];
            }
          })
          .catch((ex) => {
            this.loading = false;
          });
      }
    },
    handleSave() {
      if (!this.voucherName) {
        this.$message.warning("请填写凭证名称!");
        return;
      }
      if (!this.voucherPrice && +this.voucherPrice <= 0) {
        this.$message.warning("请填写凭证金额!");
        return;
      }
      if (this.picFileList.length === 0) {
        this.$message.warning("请上传附件!");
        return;
      }
      if (!this.voucherType) {
        this.$message.warning("请选择凭证类型!");
        return;
      }

      this.loading = true;

      const urls = this.picFileList.map((item) => {
        return this.getUploadFileUrl(item);
      });

      let voucherUrl = urls.join(",");

      const data = {
        id: this.id,
        voucherName: this.voucherName,
        voucherPrice: this.voucherPrice,
        voucherUrl: voucherUrl,
        voucherType: this.voucherType,
      };

      if (this.action === 1) {
        uploadingVoucher(data)
          .then((res) => {
            if (res) {
              this.$emit("onRefresh");
              this.dialogVisible = false;
            }
            this.loading = false;
          })
          .catch((ex) => {
            this.loading = false;
          });
      } else {
        updateVoucher(data)
          .then((res) => {
            if (res) {
              this.$emit("onRefresh");
              this.dialogVisible = false;
            }
            this.loading = false;
          })
          .catch((ex) => {
            this.loading = false;
          });
      }
    },
    handleAuditOk() {
      this.doAudit(1);
    },
    handleRejust() {
      this.$refs.refRejustReason.open();
    },
    handleAuditFail(params) {
      this.doAudit(2, params.reason);
    },
    doAudit(type, reason = "") {
      const data = {
        id: this.id,
        status: type == 1 ? "PASS" : "NOT_PASS",
        reason: reason,
      };
      examineVoucher(data).then((res) => {
        if (res) {
          this.$refs.refRejustReason.close();
          this.$emit("onRefresh");
          this.close();
        }
      });
    },
    getUploadFileUrl(item) {
      let netUrl = "";
      if (item) {
        if (item.response && item.response.data && item.response.data.url) {
          netUrl = item.response.data.url;
        }
        if (!netUrl && item.url) {
          //可能是已经上传过的
          netUrl = item.url;
        }
      }
      return netUrl;
    },
    parseToUploadUrl(newUrl) {
      if (newUrl) {
        const name = getOssSrcFileName(newUrl); //newUrl.split("/");
        return { name: name, url: newUrl };
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.voucher-detail-root {
  .form-content {
    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 0;
      box-sizing: border-box;

      &.mutlti {
        align-items: flex-start;
      }

      &.contine {
        padding-top: 0;
      }

      &.big {
        .tip {
          height: 32px;
          line-height: 32px;
        }
      }

      .tip {
        min-width: 66px;
        // text-align: right;
        font-size: 14px;
        font-weight: 400;
        // text-align: right;
        color: #262626;
        text-align-last: justify;
      }

      .value {
        margin-left: 16px;

        &.input {
          width: 204px;

          ::v-deep .el-date-editor.el-input,
          ::v-deep .el-date-editor.el-input__inner {
            width: 100%;
          }

          ::v-deep .el-select {
            width: 100%;
          }
        }

        &.fill {
          flex: 1;
          width: 0;
        }

        .small-tip {
          font-size: 14px;
          font-weight: 400;
          text-align: left;
          color: #8c8c8c;

          &.warning {
            color: #e6a23c;
          }
        }

        &.mutlti {
          flex: 1;
          width: 0;
          display: flex;

          &.v {
            flex-direction: column;

            .in-row {
              &:not(:first-child) {
                margin-top: 8px;
              }
            }
          }
        }

        .flag-block {
          width: 100%;
          background: #fafafa;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 8px 16px 16px 16px;
          box-sizing: border-box;
          flex-wrap: wrap;

          .flag-title {
            font-size: 14px;
            font-weight: 400;
            color: #262626;
            margin-right: 8px;
          }

          & > * {
            margin-top: 8px;
          }
        }
      }

      .in-row {
        .value {
          margin-left: 0;
        }
      }

      &.small {
        padding-bottom: 0;
        .tip {
          height: auto;
        }
        .value {
          color: #a0a0a0;
        }
      }
    }

    .opinion {
      color: #f00;
    }

    .goods-info {
      display: inline-flex;
      align-items: center;
      margin-right: 24px;

      .txt-info {
        display: flex;
        flex-direction: column;
        margin-left: 12px;
        .name {
          font-size: 16px;
          font-weight: 600;
          color: var(--color-26);
        }
        .factory {
          color: var(--color-59);
          font-size: 14px;
          margin-top: 3px;
        }
      }
    }
  }

  .rejected-layout {
    margin-top: 24px;
    .title {
      font-size: 15px;
      font-weight: 600;
      color: #262626;
      padding: 12px 0;
      box-sizing: border-box;
    }

    .v-list {
      display: flex;
      flex-direction: column;

      .v-list-item {
        display: flex;

        .left {
          width: 14px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .line {
            width: 2px;
            flex: 1;
            background-color: #f0f0f0;
          }
          .dot {
            width: 8px;
            height: 8px;
            border-radius: 999px;
            background-color: #c0c0c0;
          }
        }

        .datas-view {
          padding: 6px 0;
          box-sizing: border-box;
          margin-left: 12px;
          display: flex;
          flex-direction: column;
          .reason {
            font-size: 15px;
            font-weight: normal;
            color: #262626;
          }
          .time {
            margin-top: 4px;
            font-size: 13px;
            font-weight: normal;
            color: #999;
          }
        }

        &:first-child {
          .left {
            .line.up {
              visibility: hidden;
            }
          }
        }
        &:last-child {
          .left {
            .line.down {
              visibility: hidden;
            }
          }
        }
      }
    }
  }

  ::v-deep .voucher-dialog {
    .el-dialog__body {
      max-height: 60vh;
      overflow-y: auto;
      min-height: 240px;
    }
  }
}
</style>
