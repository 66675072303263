<template>
  <div class="dialog">
    <el-dialog
      title="开票"
      :visible.sync="visible"
      :lock-scroll="true"
      width="1380px"
      :before-close="handleClose"
    >
      <div class="stepView">
        <el-steps
          :active="stepNumber"
          finish-status="success"
          simple
          style="margin-top: 20px"
        >
          <el-step title="选择开票主体"></el-step>
          <el-step title="数电发票账号登录"></el-step>
          <el-step title="选择订单后开票"></el-step>
        </el-steps>
      </div>
      <invoicing-step-three
        v-if="stepNumber == 3"
        @submit="submit"
        ref="invoicingStepThree"
        @close="handleClose"
      ></invoicing-step-three>
      <invoicing-step-two
        v-if="stepNumber == 2"
        @submit="submit"
        ref="invoicingStepTwo"
        :source="'invoicingStepTwo'"
        @close="handleClose"
      ></invoicing-step-two>
      <invoicing-step-one
        ref="invoicingStepOne"
        v-if="stepNumber == 1"
        @submit="submit"
        @close="handleClose"
      ></invoicing-step-one>
    </el-dialog>
  </div>
</template>
<script>
import invoicingStepThree from "./invoicingStepThreeCSO.vue";
import invoicingStepOne from "./invoicingStepOneCSO.vue";
import invoicingStepTwo from "./invoiceLoginCSO.vue";
export default {
  components: { invoicingStepThree, invoicingStepOne, invoicingStepTwo },
  props: {},
  computed: {
    visible() {
      return this.dialogShow;
    },
  },
  data() {
    return {
      stepNumber: 1,
      tableData: [],
      formTool: {},
      invoiceOptions: [],
      dialogShow: false,
      areaList: [],
      newAreaList: [],
      promoteRateNew: "",
      companyId: "",
      companyItem: {},
    };
  },
  created() {},
  methods: {
    handleClose() {
      this.dialogShow = false;
    },

    submit(step, id, item) {
      if (item) {
        this.companyItem = item;
      }
      if (id) {
        this.companyId = id;
      }
      if (step == "first") {
        this.stepNumber = 2;
        this.$nextTick(() => {
          this.$refs.invoicingStepTwo.init(
            this.companyId,
            "invoicingStepTwo",
            this.companyItem
          );
        });
      }
      if (step == "two") {
        this.stepNumber = 3;
        this.$nextTick(() => {
          this.$refs.invoicingStepThree.init(
            this.companyId,
            "invoicingStepThree",
            this.companyItem
          );
        });
      }
    },

    init() {
      this.stepNumber = 1;
      this.dialogShow = true;
      console.log(
        this.$refs.invoicingStepOne,
        "---this.$refs.invoicingStepOne"
      );
      console.log(this.stepNumber, "---this.stepNumber");
      if (this.stepNumber == 1) {
        this.$nextTick(() => {
          this.$refs.invoicingStepOne.init();
        });
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/variables.scss";

.btnBox {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.classification {
  padding: 0px;

  .header {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0px 24px 0px 24px !important;
    .font {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      text-align: left;
      color: #262626;
    }

    .el-form-item {
      margin: 0px 0px 0px 8px !important;
    }
  }

  background: white;

  .top {
    display: flex;
    padding: 10px 0 10px 0;

    .item {
      display: flex;
      align-items: center;
      margin-right: 20px;

      .label {
        width: 120px;
      }

      .button {
        margin-right: 20px;

        ::v-deep .el-button {
          color: $subMenuActiveText;
          background-color: $butColor;
          border-color: $butColor;

          &:hover {
            color: $subMenuActiveText;
            background-color: $butColor;
            border-color: $butColor;
          }

          &:active {
            color: $subMenuActiveText;
            background: $butColor;
            background-color: $butColor;
            border-color: $butColor;
          }

          &:focus {
            color: $subMenuActiveText;
            background-color: $butColor;
            border-color: $butColor;
          }
        }
      }
    }
  }

  .center {
    margin-top: 16px;
    padding: 0 24px;
    height: calc(100% - 131px);

    .shangjia {
      position: absolute;
      z-index: 2;
      bottom: -45px;
    }
  }

  .btn-warp {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.btn-list {
  margin: 0 5px;
}
.loginMain {
  height: 500px !important;
}
</style>
