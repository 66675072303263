<template>
  <div class="static-template">
    <div
      class="template-item"
      v-for="(item, index) in templateList"
      :key="index"
    >
      <div class="tip" v-if="!item.hide">
        {{ item.filedLabel }}<span v-if="item.isMust" class="weight">*</span
        ><el-button
          size="mini"
          v-if="!preview && item.useDefaultFile"
          @click="handleOpenFileDlg(item)"
          >附件</el-button
        >
      </div>
      <!-- 附件域 -->
      <div v-if="item.useDefaultFile && item.defaultValue">
        <div
          v-for="(fileItem, fileIndex) in getFileList(item)"
          :key="fileIndex"
        >
          <div @click="handlePreviewFile(fileItem)" class="file-item">
            {{ fileItem.name }}
          </div>
        </div>
      </div>
      <!-- 普通输入域 -->
      <div
        class="value"
        v-if="!item.hide && item.type === fieldType.Iconst_Input"
      >
        <span v-if="preview">{{
          childTaskDetail ? childTaskDetail[item.fieldName] : ""
        }}</span>
        <el-input
          v-else
          :disabled="!item.useDefault"
          v-model="item.defaultValue"
          size="small"
          placeholder=""
          multiline
          type="textarea"
        />
      </div>
      <!-- 图片域 -->
      <div
        class="value"
        v-else-if="!item.hide && item.type === fieldType.Iconst_Pic"
      >
        <ossFileUpload
          v-if="preview"
          :value="fileList(item)"
          :ossUpload="true"
          :withCover="true"
          :disabled="true"
          :picSize="88"
          :listType="item.listType || 'picture-card'"
        />
        <div v-else class="pic-views">
          <div class="select-pic">
            <i class="el-icon-plus"></i>
            <span></span>
          </div>
          <!-- <div class="small-tip">单个图片/文件大小不超过300M</div>
          <div class="small-tip">支持格式：gif、png、jpg、jpeg、bmp</div> -->
        </div>
      </div>
    </div>
    <taskFileDlg ref="taskFileDlgRef" title="课件附件" @submit="handleSubmit" />
  </div>
</template>
<script>
import ossFileUpload from "@/components/ossFileUpload";
import { taskHelp, childTaskHelp } from "@/utils/viewHelp";
import { parseToUploadUrl } from "@/utils/oss";
import { cloneDeep } from "lodash";
import taskFileDlg from "./taskFileDlg.vue";

export default {
  components: { ossFileUpload, taskFileDlg },
  props: {
    //是不是预览
    preview: {
      type: Boolean,
      default: false,
    },
    childTaskType: {
      type: Number,
      default: -1,
    },
    childTaskDetail: {
      type: Object | null,
      default: null,
    },
  },
  data() {
    return {
      fieldType: childTaskHelp.fieldType,
      value: "",
      templateList: [],
    };
  },
  computed: {
    // list() {
    //   if (this.childTaskType > 0) {
    //     const findedItem = taskHelp.taskTypeList.find(
    //       (item) => item.value === this.childTaskType
    //     );
    //     if (findedItem && Array.isArray(findedItem.template)) {
    //       this.templateList = findedItem.template;
    //       return findedItem.template;
    //     }
    //     return [];
    //   }
    //   return [];
    // },
  },
  methods: {
    fileList(item) {
      if (this.childTaskDetail && this.childTaskDetail[item.fieldName]) {
        const imgUrl = this.childTaskDetail[item.fieldName];

        const urls = imgUrl.split("||");
        const picFileList = urls.map((item) => {
          return parseToUploadUrl(item);
        });
        return picFileList;
      }

      return [];
    },
    publicGetDefaultValue() {
      return this.templateList;
    },
    publicSetSalesManDefaultValue(val = null) {
      if (this.childTaskType > 0) {
        const findedItem = taskHelp.taskTypeList.find(
          (item) => item.value === this.childTaskType
        );
        if (findedItem && Array.isArray(findedItem.template)) {
          let temp = cloneDeep(findedItem.template);
          if (val) {
            const allKeys = Object.keys(val);
            const allValues = Object.values(val);
            this.templateList = temp.map((item) => {
              if (allKeys.includes(item.fieldName)) {
                const filedValue = allValues[allKeys.indexOf(item.fieldName)];
                if (item.useDefaultFile) {
                  item.defaultValue = filedValue ? JSON.parse(filedValue) : [];
                } else {
                  item.defaultValue = filedValue;
                }
              }
              return item;
            });
          } else {
            if (findedItem.value === 2) {
              //"学术培训"
              if (this.childTaskDetail.photoUrlSelected) {
                temp.forEach((item) => {
                  if (item.fieldName === "trainCoursewareImgUrl") {
                    item.defaultValue = [
                      JSON.parse(this.childTaskDetail.photoUrlSelected),
                    ];
                  }
                });
              }
            } else if (findedItem.value === 4) {
              // 终端开发
              temp.forEach((item) => {
                if (item.fieldName === "displayImgUrl") {
                  if (this.childTaskDetail.custId == 4) {
                    item.filedLabel = "医院准入证明文件";
                  } else {
                    item.filedLabel = "产品陈列图片";
                  }
                }
              });
            }
            this.templateList = temp;
          }
        }
      }
    },
    handleOpenFileDlg(item) {
      this.$refs.taskFileDlgRef.publicOpen(item);
    },
    handleSubmit(fileList) {
      this.templateList.forEach((item) => {
        if (item.useDefaultFile) {
          if (item.fieldName === "trainCoursewareImgUrl") {
            //暂时只一种
            item.defaultValue = fileList.map((item) => {
              return parseToUploadUrl(item);
            });
          }
        }
      });
    },
    getFileList(item) {
      const { defaultValue } = item;
      if (defaultValue) {
        return defaultValue;
      }
      return [];
    },
    handlePreviewFile(e) {
      const { name, value } = e;
      if (!value) {
        return;
      }
      window.open(value);
    },
  },
};
</script>
<style lang="scss" scoped>
.static-template {
  .template-item {
    .tip {
      font-size: 14px;
      color: #a0a0a0;

      .weight {
        color: #f00;
        position: relative;
        top: 4px;
        margin-left: 2px;
      }
    }

    .value {
      margin-top: 4px;
      color: #000;

      ::v-deep {
        .el-input {
          width: 100%;
        }
      }

      .pic-views {
        display: flex;
        flex-direction: column;

        .small-tip {
          font-size: 12px;
          color: #a0a0a0;
          margin-top: 12px;
        }
      }

      .select-pic {
        width: 110px;
        height: 110px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px dashed #f0f0f0;
        padding: 24px;
        box-sizing: border-box;
        border-radius: 4px;
      }
    }
  }

  .position {
    background: #eff3fd;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 4px;
  }

  & > * {
    margin-bottom: 12px;
  }
  .file-item {
    font-size: 14px;
    line-height: 2;
    cursor: pointer;
    &:hover {
      color: #f00;
    }
  }
}
</style>
