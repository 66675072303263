import request from "@/utils/request";
import { Message } from "element-ui";

// 分页查询CSO业务员
export function csoMemberPage(data) {
  return request({
    url: "/shop/hg/csoMember/page",
    method: "get",
    params: data,
  });
}

// 添加CSO业务员
export function csoMemberAdd(data) {
  return request({
    url: "/shop/hg/csoMember/add",
    method: "POST",
    data,
  });
}

// 编辑CSO业务员
export function csoMemberEdit(data) {
  return request({
    url: "/shop/hg/csoMember/edit",
    method: "PATCH",
    data,
  });
}

// 查看CSO业务员
export function csoMemberDetail(id) {
  return request({
    url: `/shop/hg/csoMember/detail/${id}`,
    method: "get",
    params: {},
  });
}

// 导出CSO业务员
export function csoMemberExport(data) {
  return request({
    url: `/shop/hg/csoMember/export`,
    method: "get",
    params: data,
    responseType: "blob",
  }).then((response) => {
    // console.log(response,'res')
    // 处理返回的文件流
    const blob = response.data;
    if ((blob && blob.size === 0) || response.size == 0) {
      Message.warning("内容为空，无法下载");
      return;
    }
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    const contentDisposition = response.headers["content-disposition"];
    const fileName = decodeURIComponent(
      contentDisposition.substring(contentDisposition.indexOf("=") + 1)
    );
    link.download = "业务员数据导出" + fileName;
    document.body.appendChild(link);
    link.click();
    window.setTimeout(function () {
      URL.revokeObjectURL(blob);
      document.body.removeChild(link);
    }, 0);
  });
}

// 查询 cso 业务员区域
export function csoMemberArea(csoMemberId) {
  return request({
    url: `/shop/hg/csoMemberArea/detail/${csoMemberId}`,
    method: "get",
    params: {},
  });
}
// 保存 cso 业务员区域
export function csoMemberAreaChangeArea(data) {
  return request({
    url: `/shop/hg/csoMemberArea/changeArea`,
    method: "POST",
    data,
  });
}
// 新增 人员资质
export function csoResourceUserAdd(data) {
  return request({
    url: `/shop/hg/csoResourceUser/add`,
    method: "POST",
    data,
  });
}
// 编辑 人员资质
export function csoResourceUserEdit(data) {
  return request({
    url: `/shop/hg/csoResourceUser/edit`,
    method: "PATCH",
    data,
  });
}
// 获取 人员资质 列表
export function csoResourceUserPage(data) {
  return request({
    url: `/shop/hg/csoResourceUser/page`,
    method: "get",
    params: data,
  });
}
// 查询人员资质详情
export function csoResourceUserDetail(id) {
  return request({
    url: `/shop/hg/csoResourceUser/detail/${id}`,
    method: "get",
    params: {},
  });
}
// 切换 人员资质状态
export function csoResourceUserSwitchEnable(id) {
  return request({
    url: `/shop/hg/csoResourceUser/switchEnable/${id}`,
    method: "PATCH",
    params: {},
  });
}
// 导出人员资质
export function csoResourceUserExport(data) {
  return request({
    url: `/shop/hg/csoResourceUser/export`,
    method: "get",
    params: data,
    responseType: "blob",
  }).then((response) => {
    // console.log(response,'res')
    // 处理返回的文件流
    const blob = response.data;
    if ((blob && blob.size === 0) || response.size == 0) {
      Message.warning("内容为空，无法下载");
      return;
    }
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    const contentDisposition = response.headers["content-disposition"];
    const fileName = decodeURIComponent(
      contentDisposition.substring(contentDisposition.indexOf("=") + 1)
    );
    link.download = "人员资质导出" + fileName;
    document.body.appendChild(link);
    link.click();
    window.setTimeout(function () {
      URL.revokeObjectURL(blob);
      document.body.removeChild(link);
    }, 0);
  });
}
// 身份证识别OCR
export function idCardOcr(data) {
  return request({
    url: `/shop/hg/csoMember/idCardOcr`,
    method: "POST",
    data,
  });
}
// 完税凭证
export function csoDutyPaidProofParentPage(data) {
  return request({
    url: `/shop/csoDutyPaidProof/parent/page`,
    method: "get",
    params: data,
  });
}
// 完税凭证列表
export function csoDutyPaidProofSubClassPage(data) {
  return request({
    url: `/shop/csoDutyPaidProof/subclass/page`,
    method: "get",
    params: data,
  });
}
// 完税凭证-医药公司
export function csoDutyPaidProofMedicalParentPage(data) {
  return request({
    url: `/shop/csoDutyPaidProof/medicalParent/page`,
    method: "get",
    params: data,
  });
}
// 完税凭证审核列表-医药公司
export function csoDutyPaidProofAllSubclassMedicalPage(data) {
  return request({
    url: `/shop/csoDutyPaidProof/allSubclass/medical/page`,
    method: "get",
    params: data,
  });
}

// 审核凭证-医药公司
export function auditCsoDutyPaidProof(data) {
  return request({
    url: "/shop/csoDutyPaidProof",
    method: "PUT",
    data,
  });
}

// 新建完税凭证
export function csoDutyPaidProofAdd(data) {
  return request({
    url: `/shop/csoDutyPaidProof`,
    method: "POST",
    data,
  });
}
// 查看完税凭证详情
export function csoDutyPaidProofDetail(id) {
  return request({
    url: `/shop/csoDutyPaidProof/${id}`,
    method: "get",
    params: {},
  });
}
// 查询合同指标任务剩余数
export function contractTargetTaskResidueCount(targetTaskId) {
  return request({
    url: `/shop/hg/contractTargetTask/residueCount/${targetTaskId}`,
    method: "get",
    params: {},
  });
}
// 分配合同任务
export function contractDoTaskAddBatch(data) {
  return request({
    url: `/shop/hg/contractDoTask/addBatch`,
    method: "POST",
    data,
  });
}
