var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "700px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        top: "10vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        close: _vm.beforClose,
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.tableData,
                border: true,
                "max-height": "500",
                "row-key": "id",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "业务员", prop: "salesmanName" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              filterable: "",
                              remote: "",
                              "reserve-keyword": "",
                              placeholder: "搜索业务员",
                              "remote-method": _vm.remoteMethod,
                              loading: _vm.loading,
                              "value-key": "id",
                            },
                            model: {
                              value: scope.row.salesman,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "salesman", $$v)
                              },
                              expression: "scope.row.salesman",
                            },
                          },
                          _vm._l(_vm.salesmanList, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: {
                                label: item.name + "/" + item.userTel,
                                value: item,
                              },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "分派子任务数", prop: "allocationCount" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input-number", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            min: 1,
                            max: Number(
                              _vm.taskCanAllocationCount +
                                scope.row.allocationCount
                            ),
                            step: 1,
                            placeholder:
                              "剩余可分配子任务数：" +
                              _vm.taskCanAllocationCount,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.handleInputChange()
                            },
                          },
                          model: {
                            value: scope.row.allocationCount,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "allocationCount", $$v)
                            },
                            expression: "scope.row.allocationCount",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "", prop: "", width: "100px", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.tableData.splice(scope.$index, 1)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _vm.taskCanAllocationCount > 0
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.handleAddRow },
                },
                [_vm._v("增加一行")]
              )
            : _vm._e(),
          _c("el-button", { on: { click: _vm.handleClose } }, [_vm._v("取消")]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitLoading },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }