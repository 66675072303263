import { render, staticRenderFns } from "./certify-detail.vue?vue&type=template&id=27919391&scoped=true"
import script from "./certify-detail.vue?vue&type=script&lang=js"
export * from "./certify-detail.vue?vue&type=script&lang=js"
import style0 from "./certify-detail.vue?vue&type=style&index=0&id=27919391&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27919391",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/godata/pipelines/eShop-Portal-Web-Prod/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('27919391')) {
      api.createRecord('27919391', component.options)
    } else {
      api.reload('27919391', component.options)
    }
    module.hot.accept("./certify-detail.vue?vue&type=template&id=27919391&scoped=true", function () {
      api.rerender('27919391', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/compliancecso/salesman/dialog/certify-detail.vue"
export default component.exports