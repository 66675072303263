<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="30%"
    @close="handleClose"
  >
    <div>
      <div class="search-container">
        <div class="search-item">区域：</div>
        <el-input
          v-model="name"
          placeholder="搜索区域"
          clearable
          size="small"
        ></el-input>
      </div>
      <div class="tree-container">
        <el-tree
          ref="cityTree"
          show-checkbox
          node-key="id"
          :default-expanded-keys="expandedKeys"
          :default-checked-keys="checkedKeys"
          :data="regionTreedata"
          :props="defaultProps"
          :filter-node-method="filterNode"
        ></el-tree>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="small">关闭</el-button>
        <el-button
          type="primary"
          @click="handleSave"
          size="small"
          :loading="saveLoading"
          >保存</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  csoMemberArea,
  csoMemberAreaChangeArea,
} from "@/api/complianceCso/salesman";
import { regionTree } from "@/api/businessPlatform/relationshipMaintenance";
export default {
  name: "SalesAreaDialog",
  props: {
    title: {
      type: String,
      default: "业务员区域",
    },
  },
  data() {
    return {
      dialogVisible: false,
      salesAreas: [],
      name: "",
      regionTreedata: [],
      defaultProps: {
        children: "children",
        label: "name",
        value: "id",
      },
      expandedKeys: [],
      checkedKeys: [],
      csoMemberId: "",
      saveLoading: false,
    };
  },
  watch: {
    name(val) {
      this.$refs.cityTree.filter(val);
    },
  },
  methods: {
    async publicOpen(csoMember) {
      this.dialogVisible = true;
      await this.regionTree();
      this.getAreaTree(csoMember.id);
      this.csoMemberId = csoMember.id;
    },
    handleSave() {
      this.saveLoading = true;
      const nodes = this.$refs.cityTree.getCheckedNodes();
      const areaCodes = nodes.map((item) => {
        return {
          areaCode: item.id,
          areaName: item.name,
        };
      });
      const obj = {
        areaList: areaCodes,
        csoMemberId: this.csoMemberId,
      };
      csoMemberAreaChangeArea(obj)
        .then((res) => {
          if (res) {
            this.$message.success("保存成功");
            this.dialogVisible = false;
          } else {
            this.$message.error(res.msg);
          }
        })
        .finally(() => {
          this.saveLoading = false;
        });
    },
    handleClose() {
      this.$refs.cityTree.setCheckedKeys([]);
      this.name = "";
      this.dialogVisible = false;
    },
    // 获取行政区树
    regionTree() {
      return new Promise((resolve) => {
        const regionTreeFromStorage = JSON.parse(
          localStorage.getItem("regionTree")
        );
        if (regionTreeFromStorage) {
          this.regionTreedata = regionTreeFromStorage;
          resolve();
          return;
        }
        regionTree().then((res) => {
          if (res.code == 0) {
            this.regionTreedata = res.data;
            localStorage.setItem("regionTree", JSON.stringify(res.data));
            resolve();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    getAreaTree(csoMemberId) {
      csoMemberArea(csoMemberId).then((res) => {
        if (res) {
          const { areaCode, csoMemberId } = res;
          this.$nextTick(() => {
            this.$refs.cityTree.setCheckedKeys(areaCode);
          });
        }
      });
    },
    // 过滤选中城市id数组
    fittlerCityId(data) {
      let arr = [];
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          arr.push(data[i].areaCode);
        }
      }
      return arr;
    },
    //树过滤不查找下级
    filterNode(value, data) {
      if (!value) return true;
      return data.name
        ? data.name.indexOf(value) !== -1
        : data.rname.indexOf(value) !== -1;
    },
  },
  created() {},
};
</script>
<style scoped lang="scss">
.search-container {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  .search-item {
    width: 50px;
  }
}
.tree-container {
  height: 400px;
  overflow: auto;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  align-items: center;
}
</style>
