var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "上传附件",
        visible: _vm.visible,
        "lock-scroll": true,
        width: "618px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        close: _vm.resetForm,
      },
    },
    [
      _c(
        "el-form",
        { attrs: { "label-width": "100px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "发票金额", required: "" } },
            [
              _c("el-input", {
                staticStyle: { width: "250px" },
                model: {
                  value: _vm.amount,
                  callback: function ($$v) {
                    _vm.amount = $$v
                  },
                  expression: "amount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "附件", required: "" } },
            [
              _c("ossFileUpload", {
                ref: "refPicFileUpload",
                staticStyle: { "margin-bottom": "10px" },
                attrs: {
                  limit: _vm.maxFileCount,
                  accept: _vm.picFileExt,
                  maxFileLength: 10,
                  ossUpload: true,
                  ossPathDir: "shop/pic/",
                  disabled: _vm.disabled,
                },
                model: {
                  value: _vm.picFileList,
                  callback: function ($$v) {
                    _vm.picFileList = $$v
                  },
                  expression: "picFileList",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.handleClose } },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }