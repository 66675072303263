import { render, staticRenderFns } from "./companyType.vue?vue&type=template&id=56f6d1cd&scoped=true"
import script from "./companyType.vue?vue&type=script&lang=js"
export * from "./companyType.vue?vue&type=script&lang=js"
import style0 from "./companyType.vue?vue&type=style&index=0&id=56f6d1cd&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56f6d1cd",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/godata/pipelines/eShop-Portal-Web-Prod/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('56f6d1cd')) {
      api.createRecord('56f6d1cd', component.options)
    } else {
      api.reload('56f6d1cd', component.options)
    }
    module.hot.accept("./companyType.vue?vue&type=template&id=56f6d1cd&scoped=true", function () {
      api.rerender('56f6d1cd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/myQualification/children/companyType.vue"
export default component.exports