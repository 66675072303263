<template>
  <div class="classification">
    <div class="header">
      <el-form
        ref="formTool"
        :model="formTool"
        :inline="true"
        style="text-align: left"
      >
        <el-form-item prop="name" label="">
          <el-input
            v-model="formTool.contractName"
            style="width: 150px"
            placeholder="请输入合同名称"
            clearable
            size="small"
          />
        </el-form-item>
        <el-form-item prop="name" label="">
          <el-input
            v-model="formTool.draftShopName"
            style="width: 180px"
            placeholder="请输入医药公司名称"
            clearable
            size="small"
          />
        </el-form-item>
        <el-form-item prop="name" label="">
          <el-input
            v-model="formTool.name"
            style="width: 150px"
            placeholder="请输入指标名称"
            clearable
            size="small"
          />
        </el-form-item>
        <el-form-item prop="name" label="">
          <el-select
            v-model="formTool.bagStatus"
            style="width: 150px"
            size="small"
            placeholder="请选择指标状态"
            clearable
          >
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="getList(1)"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="handleReset">重置</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="handleExport"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="center" style="position: relative">
      <MyTable
        :index="false"
        :table-data="tableData"
        :page="page"
        :table-option.sync="tableOption"
        @page-change="getList"
      >
        <template slot="bagStatus" slot-scope="scope">
          <span>{{ status(scope.row.bagStatus) }}</span>
        </template>
        <template slot="createTime" slot-scope="scope">
          <div
            v-if="scope.row.createTime"
            style="display: flex; flex-direction: column"
          >
            <span v-for="(li, i) in scope.row.createTime.split(' ')" :key="i">{{
              li
            }}</span>
          </div>
        </template>
        <template slot="operate" slot-scope="scope">
          <div class="table-op-col">
            <el-link
              type="primary"
              :underline="false"
              size="small"
              @click="handleLookTask(scope.$index)"
              >任务列表</el-link
            >
            <!-- <el-link
              v-if="scope.row.bagStatus == bagStatusValues[3]"
              type="primary"
              :underline="false"
              size="small"
              @click="handleVoucher(scope.$index, 4)"
              >审核凭证</el-link
            >
            <el-link
              v-if="scope.row.bagStatus == bagStatusValues[4] || scope.row.bagStatus == bagStatusValues[5]"
              type="primary"
              :underline="false"
              size="small"
              @click="handleVoucher(scope.$index, 3)"
              >查看凭证</el-link
            > -->
          </div>
        </template>
      </MyTable>
    </div>
    <opVoucherDlg ref="refOpVoucherDlg" @onRefresh="handleRefresh" />
  </div>
</template>

<script>
import MyTable from "@/components/myTable";
import opVoucherDlg from "@/views/compliance/metricsManager/dialog/opVoucherDlg.vue";
import {
  contractTargetPage,
  exportContractTarget,
} from "@/api/compliance/metricsManager";
import { metricsHelp } from "@/utils/viewHelp";

export default {
  components: { MyTable, opVoucherDlg },
  data() {
    return {
      bagStatusValues: Object.keys(metricsHelp.bagStatusList),
      formTool: {
        contractName: "",
        draftShopName: "",
        name: "",
        bagStatus: "",
      },
      tableData: [],
      tableOption: [
        { label: "合同名称", prop: "contractName" },
        { label: "合同编码", prop: "contractCode" },
        { label: "医药公司", prop: "draftShopName" },
        { label: "指标名称", prop: "name" },
        { label: "指标金额", prop: "price" },
        {
          label: "已完成指标金额",
          prop: "completeTargetAmount",
          width: "140px",
        },
        { label: "指标任务数", prop: "targetTaskNumber" },
        {
          label: "完成指标任务数",
          prop: "targetTaskCompleteNumber",
          width: "140px",
        },
        { label: "状态", prop: "bagStatus", slot: true },
        { label: "创建人", prop: "createBy" },
        { label: "创建时间", prop: "createTime", width: "100px", slot: true },
        { label: "操作", prop: "operate", slot: true, width: "220px" },
      ],
      page: { total: 0, current: 1, size: 10 },
    };
  },
  created() {
    this.getList(1);
  },
  computed: {
    statusList() {
      let list = [];
      for (let key in metricsHelp.bagStatusList) {
        list.push({ value: key, label: metricsHelp.bagStatusList[key] });
      }

      return list;
    },
  },
  methods: {
    status(bagStatus) {
      return metricsHelp.status(bagStatus);
    },
    handleLookTask(index) {
      const row = this.tableData[index];
      this.$router.push({
        path: "/compliancecso/compliancecso-task-list",
        query: {
          targetId: row.id,
        },
      });
    },
    handleEdit(index) {
      const row = this.tableData[index];
    },
    getList(num) {
      if (num) {
        this.page.current = num;
      }
      const { current, size } = this.page;
      let params = { clientTag: "CSO", current, size, ...this.formTool };
      this.listLoading = true;
      contractTargetPage(params)
        .then((res) => {
          this.tableData = res.records;
          this.page.total = res.total;
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    handleRefresh() {
      this.getList(1);
    },
    handleReset() {
      for (let key in this.formTool) {
        this.formTool[key] = "";
      }
      this.getList(1);
    },
    handleExport() {
      // 导出
      exportContractTarget({ ...this.formTool, clientTag: "CSO" }).then(
        (response) => {
          if (response) {
            const blob = response.data;
            const fileName = "统计.xlsx";
            const downloadLink = document.createElement("a");
            const href = window.URL.createObjectURL(blob); // 创建下载的链接
            downloadLink.href = href;
            downloadLink.download = fileName; // 下载后文件名
            document.body.appendChild(downloadLink);
            downloadLink.click();
            window.URL.revokeObjectURL(href); // 释放掉blob对象
            document.body.removeChild(downloadLink); // 下载完成移除元素
          }
        }
      );
    },
    handleVoucher(index, action) {
      const row = this.tableData[index];
      this.openOpVoucher({ action, id: row.id });
    },
    openOpVoucher(options) {
      this.$refs.refOpVoucherDlg.open(options);
    },
  },
};
</script>

<style lang="scss" scoped>
.classification {
  padding: 0px;

  .header {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px 0px 24px !important;
    border-bottom: 1px solid #f5f5f5;

    .font {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      text-align: left;
      color: #262626;
    }

    .el-form-item {
      margin: 0px 0px 0px 8px !important;
    }
  }

  background: white;

  .center {
    margin-top: 16px;
    padding: 0 24px;
    height: calc(100% - 131px);

    .shangjia {
      position: absolute;
      z-index: 2;
      bottom: -45px;
    }
  }

  .btn-warp {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .el-link {
      // margin: 5px;
    }
  }

  .table-op-col {
    display: flex;
    .el-link {
      & + .el-link {
        margin-left: 8px;
      }
    }
  }
}
</style>
