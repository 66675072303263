<template>
  <div class="detail-root">
    <el-dialog
      :title="title"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="918px"
      @closed="onClosed"
      v-loading="loading"
    >
      <div class="content form-content">
        <div class="row mutlti big">
          <div class="tip"><span class="point">*</span>完税凭证金额</div>
          <div class="value input">
            <el-input
              size="small"
              maxlength="20"
              v-model.trim="formData.amount"
              placeholder="请输入完税凭证金额"
              :disabled="isLook || isCheck"
            ></el-input>
          </div>
        </div>
        <div class="row mutlti big">
          <div class="tip"><span class="point">*</span>凭证</div>
          <div class="value input">
            <ossFileUpload
              key="1"
              ref="refUserFileUpload"
              v-model="receipts"
              :limit="3"
              :accept="picFileExt"
              :maxFileLength="10"
              :ossUpload="true"
              :ossPathDir="'shop/pic/'"
              style="margin-bottom: 10px"
              :disabled="isLook || isCheck"
            >
            </ossFileUpload>
            <div class="small-tip warning" v-if="!isLook && !isCheck">
              <span
                >请上传凭证。格式要求：pdf或图片，且不超过10M，最多上传3个。</span
              >
            </div>
          </div>
        </div>
        <!-- 审核结果 通过 驳回 -->
        <div class="row mutlti big" v-if="isCheck">
          <div class="tip"><span class="point">*</span>审核结果</div>
          <div class="value input" style="margin-top: 8px">
            <el-radio-group
              v-model="formData.auditStatus"
              :disabled="!isCheck"
              @change="handleAuditStatusChange"
            >
              <el-radio label="3">通过</el-radio>
              <el-radio label="1">驳回</el-radio>
            </el-radio-group>
            <div class="value-desc"></div>
          </div>
        </div>
        <!-- 驳回原因-->
        <div
          class="row mutlti big"
          v-if="isCheck && formData.auditStatus === '1'"
        >
          <div class="tip"><span class="point">*</span>驳回原因</div>
          <div class="value input" style="margin-top: 8px">
            <el-input
              type="textarea"
              v-model="formData.rejectReason"
              :disabled="!isCheck"
              placeholder="请输入驳回原因"
            ></el-input>
            <div class="value-desc"></div>
          </div>
        </div>
      </div>
      <OpsCheck
        :rejectHistory="memberInfo?.voucherRefusalList || []"
        auditStatusPass="2, 3"
        auditRejectKey="reason"
        auditStatusKey="status"
        createName="auditUser"
        createTime="auditDate"
      ></OpsCheck>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="close">取 消</el-button>
        <el-button
          v-if="!isLook"
          size="small"
          type="primary"
          @click="handleSave"
          >提交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  csoDutyPaidProofAdd,
  csoDutyPaidProofDetail,
  auditCsoDutyPaidProof,
} from "@/api/complianceCso/salesman";
import ossFileUpload from "@/components/ossFileUpload";
import OpsCheck from "@/views/compliancecso/salesman/components/ops-check.vue";

export default {
  components: {
    ossFileUpload,
    OpsCheck,
  },
  data() {
    return {
      action: 1, //1 新增  2 编辑 3 查看 4审核
      dialogVisible: false,
      mainId: null, //
      loading: false,
      formData: {
        amount: "",
        csoMemberId: null, //
        targetTaskId: null, //
        auditStatus: null, // 2 通过 1 驳回
        rejectReason: "", // 驳回原因
      },
      memberInfo: null,
      receipts: [],
      picFileExt: ".jpg,.png,.pdf,.jpeg",
    };
  },
  computed: {
    title() {
      let txt = "";
      if (this.action === 2) {
        txt = `编辑${txt}`;
      } else if (this.action === 3) {
        txt = `查看${txt}`;
      } else if (this.action === 4) {
        txt = `审核${txt}`;
      } else {
        txt = `新增${txt}`;
      }
      return txt;
    },
    isLook() {
      return this.action === 3;
    },
    isCheck() {
      return this.action === 4;
    },
  },
  methods: {
    handleAuditStatusChange(val) {
      this.formData.rejectReason = "";
    },
    open(options = null) {
      if (options) {
        const { action, row } = options;
        this.action = action || 1;
        this.formData.csoMemberId = row.csoMemberId;
        this.formData.targetTaskId = row.targetTaskId;
        if (row && row.id) {
          this.mainId = row.id;
          this.getDetail();
        }
      }
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.refUserFileUpload.init(); //初始化oss上传
      });
    },
    close() {
      this.dialogVisible = false;
    },
    onClosed() {
      this.reset();
    },
    reset() {
      this.action = 1;
      this.mainId = "";
      this.formData = {
        amount: "",
        csoMemberId: null, //
        targetTaskId: null, //
        auditStatus: null, // 2 通过 1 驳回
        rejectReason: "", // 驳回原因
      };
      this.memberInfo = null;
      this.receipts = [];
      this.loading = false;
    },
    getDetail() {
      if (this.mainId) {
        this.loading = true;
        // 替换新的获取详情方法
        csoDutyPaidProofDetail(this.mainId)
          .then((data) => {
            if (data) {
              this.memberInfo = data;
              this.formData.amount = data.taskAmount;
              let filesData = data.voucherUrl ? data.voucherUrl.split(",") : [];
              let arr = [];
              if (filesData && filesData.length > 0) {
                for (let i = 0; i < filesData.length; i++) {
                  // 处理 url 按/分割 取最后一个字符串作为文件名
                  const name = filesData[i].split("/").pop();
                  // 转码 decodeURI 处理中文乱码
                  const nameDecode = decodeURI(name);
                  // 处理文件名 去掉后缀名
                  arr.push({ name: nameDecode, url: filesData[i] });
                }
                this.receipts = arr;
              }
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    handleSave() {
      const { mainId, formData, receipts } = this;
      if (this.action === 4) {
        const { rejectReason, auditStatus } = this.formData;
        if (!auditStatus) {
          this.$message.warning("请选择审核结果");
          return;
        }
        if (auditStatus === "1" && !rejectReason) {
          this.$message.warning("请输入驳回原因");
          return;
        }
        const obj = {
          id: mainId,
          status: auditStatus,
        };
        if (auditStatus === "1") {
          obj.reason = rejectReason;
        }
        this.loading = true;
        // 1:驳回,2:平台审核通过,3:医药公司审核通过
        auditCsoDutyPaidProof(obj)
          .then((res) => {
            if (res) {
              this.$emit("onRefresh");
              this.dialogVisible = false;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        const { amount } = this.formData;
        if (!amount) {
          this.$message.warning("完税凭证金额不能为空");
          return;
        }
        if (receipts.length == 0) {
          this.$message.warning("凭证不能为空");
          return;
        }
        let files_a = receipts.map((li) => this.getUploadFileUrl(li, 1));
        const data = {
          ...formData,
          voucherUrl: files_a.map((li) => li.url).join(","),
        };
        this.loading = true;
        if (mainId) {
          data.id = mainId;
        }
        csoDutyPaidProofAdd(data)
          .then((res) => {
            if (res) {
              this.$emit("onRefresh");
              this.dialogVisible = false;
            }
            this.loading = false;
          })
          .catch((ex) => {
            this.loading = false;
          });
      }
    },
    getUploadFileUrl(item, type = 1) {
      let netUrl = "";
      if (item) {
        if (item.url) {
          //可能是已经上传过的
          netUrl = item.url;
        } else if (
          //服务器返回过来的
          item.response &&
          item.response.data &&
          item.response.data.url
        ) {
          netUrl = item.response.data.url;
        }
      }
      // attType = 1 身份证 2 毕业证 3 医药/药师资格证 4 其他材料
      return { name: item.name, url: netUrl, attType: type };
    },
    parseToUploadUrl(el) {
      const { url, name } = el;
      // attachUrl 取最后一个.后面的字符串 作为文件类型
      if (url) {
        const reg = /(\.\w+)$/;
        const type = reg.exec(url)[1];
        const paddName = type ? name + type : name;
        return { name: paddName, url: url };
      }
      return { name: name, url: "" };
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-root {
  .form-content {
    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 0;
      box-sizing: border-box;
      &.mutlti {
        align-items: flex-start;
      }
      &.big {
        .tip {
          height: 32px;
          line-height: 32px;
        }
      }
      .tip {
        width: 8em;
        text-align: right;
        font-size: 14px;
        font-weight: 400;
        text-align: right;
        color: #262626;
        span.point {
          color: #f00;
          margin-right: 2px;
        }
      }
      .value {
        margin-left: 16px;
        &-desc {
          line-height: 1;
          margin-top: 6px;
          color: #8c8c8c;
          font-size: 13px;
        }
        &.input {
          width: calc(100% - 200px);
        }
        &.fill {
          flex: 1;
          width: 0;
        }
        .small-tip {
          font-size: 14px;
          font-weight: 400;
          text-align: left;
          color: #8c8c8c;
          &.warning {
            color: #e6a23c;
          }
        }
        &.mutlti {
          flex: 1;
          width: 0;
          display: flex;
        }
      }
    }
  }
  ::v-deep .el-dialog__body {
    max-height: 60vh;
    overflow-y: auto;
    min-height: 240px;
  }
}
</style>
