var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detail-root" },
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            title: _vm.title,
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            width: "918px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            closed: _vm.onClosed,
          },
        },
        [
          _c("div", { staticClass: "content form-content" }, [
            _c("div", { staticClass: "row mutlti big" }, [
              _c("div", { staticClass: "tip" }, [
                _c("span", { staticClass: "point" }, [_vm._v("*")]),
                _vm._v("人员姓名"),
              ]),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      maxlength: "20",
                      placeholder: "请输入姓名",
                    },
                    model: {
                      value: _vm.formData.name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formData.name",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row mutlti big" }, [
              _c("div", { staticClass: "tip" }, [
                _c("span", { staticClass: "point" }, [_vm._v("*")]),
                _vm._v("联系方式"),
              ]),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      maxlength: "20",
                      disabled: _vm.action === 2,
                      placeholder: "请输入账号",
                    },
                    model: {
                      value: _vm.formData.tel,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData,
                          "tel",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formData.tel",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row mutlti big" }, [
              _c("div", { staticClass: "tip" }, [
                _c("span", { staticClass: "point" }, [_vm._v("*")]),
                _vm._v("身份证"),
              ]),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("ossFileUpload", {
                    key: "1",
                    ref: "refUserFileUpload",
                    staticStyle: { "margin-bottom": "10px" },
                    attrs: {
                      limit: 3,
                      accept: _vm.picFileExt,
                      maxFileLength: 10,
                      ossUpload: true,
                      ossPathDir: "shop/pic/",
                      disabled: _vm.isLook,
                    },
                    model: {
                      value: _vm.userFiles,
                      callback: function ($$v) {
                        _vm.userFiles = $$v
                      },
                      expression: "userFiles",
                    },
                  }),
                  !_vm.isLook
                    ? _c("div", { staticClass: "small-tip warning" }, [
                        _c("span", [
                          _vm._v(
                            "请上传身份证正反面。格式要求：pdf或图片，且不超过10M，最多上传3个。"
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row mutlti big" }, [
              _c("div", { staticClass: "tip" }, [_vm._v("毕业证")]),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("ossFileUpload", {
                    key: "2",
                    ref: "refContractFileUpload",
                    staticStyle: { "margin-bottom": "10px" },
                    attrs: {
                      limit: 5,
                      accept: _vm.picFileExt,
                      maxFileLength: 10,
                      ossUpload: true,
                      ossPathDir: "shop/pic/",
                      disabled: _vm.isLook,
                    },
                    model: {
                      value: _vm.schoolFiles,
                      callback: function ($$v) {
                        _vm.schoolFiles = $$v
                      },
                      expression: "schoolFiles",
                    },
                  }),
                  !_vm.isLook
                    ? _c("div", { staticClass: "small-tip warning" }, [
                        _c("span", [
                          _vm._v(
                            "格式要求：上传pdf或图片，且不超过10M，最多上传5个。"
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row mutlti big" }, [
              _c("div", { staticClass: "tip" }, [_vm._v("医师/药师资格证")]),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("ossFileUpload", {
                    key: "3",
                    ref: "refEmployeeFileUpload",
                    staticStyle: { "margin-bottom": "10px" },
                    attrs: {
                      limit: 5,
                      accept: _vm.picFileExt,
                      maxFileLength: 10,
                      ossUpload: true,
                      ossPathDir: "shop/pic/",
                      disabled: _vm.isLook,
                    },
                    model: {
                      value: _vm.medicalFiles,
                      callback: function ($$v) {
                        _vm.medicalFiles = $$v
                      },
                      expression: "medicalFiles",
                    },
                  }),
                  !_vm.isLook
                    ? _c("div", { staticClass: "small-tip warning" }, [
                        _c("span", [
                          _vm._v(
                            "格式要求：上传pdf或图片，且不超过10M，最多上传5个。"
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row mutlti big" }, [
              _c("div", { staticClass: "tip" }, [_vm._v("其他")]),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("ossFileUpload", {
                    key: "4",
                    ref: "refOtherFileUpload",
                    staticStyle: { "margin-bottom": "10px" },
                    attrs: {
                      limit: 5,
                      accept: _vm.picFileExt,
                      maxFileLength: 10,
                      ossUpload: true,
                      ossPathDir: "shop/pic/",
                      disabled: _vm.isLook,
                    },
                    model: {
                      value: _vm.otherFiles,
                      callback: function ($$v) {
                        _vm.otherFiles = $$v
                      },
                      expression: "otherFiles",
                    },
                  }),
                  !_vm.isLook
                    ? _c("div", { staticClass: "small-tip warning" }, [
                        _c("span", [
                          _vm._v(
                            "格式要求：上传pdf或图片，且不超过10M，最多上传5个。"
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
          _c("OpsCheck", {
            attrs: {
              rejectHistory: _vm.memberInfo?.auditLogList || [],
              auditStatusPass: 2,
            },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.close } },
                [_vm._v("取 消")]
              ),
              !_vm.isLook
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.handleSave },
                    },
                    [_vm._v("保存并提交")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }