<template>
  <div class="detail-root">
    <el-dialog
      :title="title"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="918px"
      @closed="onClosed"
      v-loading="loading"
    >
      <div class="content form-content">
        <!-- 业务员类型 内部业务员 第三方业务员-->
        <div class="row mutlti big">
          <div class="tip"><span class="point">*</span>业务员类型</div>
          <div class="value input" style="margin-top: 8px">
            <el-radio-group v-model="formData.type">
              <el-radio label="0">内部业务员</el-radio>
              <el-radio label="1">第三方业务员</el-radio>
            </el-radio-group>
            <div class="value-desc"></div>
          </div>
        </div>
        <div class="row mutlti big">
          <div class="tip"><span class="point">*</span>业务员身份信息</div>
          <div class="value input">
            <ossFileUpload
              key="1"
              ref="refUserFileUpload"
              v-model="userFiles"
              :limit="3"
              :accept="picFileExt"
              :maxFileLength="10"
              :ossUpload="true"
              :ossPathDir="'shop/pic/'"
              style="margin-bottom: 10px"
              :disabled="isLook"
            >
            </ossFileUpload>
            <div class="small-tip warning" v-if="!isLook">
              <span
                >请按顺序上传业务员身份证正面/反面。格式要求：pdf或图片，且不超过10M，最多上传3个。上传多个文件时，将按文件上传的顺序给业务员展示资料</span
              >
            </div>
          </div>
        </div>
        <div class="row mutlti big">
          <div class="tip"><span class="point">*</span>业务员姓名</div>
          <div class="value input">
            <el-input
              size="small"
              maxlength="20"
              v-model="formData.name"
              placeholder="请输入姓名"
            ></el-input>
          </div>
        </div>
        <div class="row mutlti big">
          <div class="tip"><span class="point">*</span>业务员身份证</div>
          <div class="value input">
            <el-input
              size="small"
              maxlength="20"
              v-model="formData.idCard"
              placeholder="请输入"
            ></el-input>
          </div>
        </div>
        <div class="row mutlti big">
          <div class="tip"><span class="point">*</span>业务员账号</div>
          <div class="value input">
            <el-input
              size="small"
              maxlength="20"
              v-model="formData.userTel"
              :disabled="action === 2"
              placeholder="请输入账号"
            ></el-input>
            <div class="value-desc">
              请输入手机号，手机号将作为业务员登录系统的账号
            </div>
          </div>
        </div>
        <div class="row mutlti big" v-show="formData.type === '0'">
          <div class="tip"><span class="point">*</span>业务员签约信息</div>
          <div class="value input">
            <ossFileUpload
              key="2"
              ref="refContractFileUpload"
              v-model="contractFiles"
              :limit="5"
              :accept="picFileExt"
              :maxFileLength="10"
              :ossUpload="true"
              :ossPathDir="'shop/pic/'"
              style="margin-bottom: 10px"
              :disabled="isLook"
            >
            </ossFileUpload>
            <div class="small-tip warning" v-if="!isLook">
              <span
                >请上传业务员合同信息。格式要求：上传pdf或图片，且不超过10M，最多上传5个。上传多个文件时，将按文件上传的顺序给业务员展示资料</span
              >
            </div>
          </div>
        </div>
        <div class="row mutlti big" v-show="formData.type === '1'">
          <div class="tip"><span class="point">*</span> 雇佣凭证</div>
          <div class="value input">
            <ossFileUpload
              key="4"
              ref="refEmployeeFileUpload"
              v-model="employeeFiles"
              :limit="5"
              :accept="picFileExt"
              :maxFileLength="10"
              :ossUpload="true"
              :ossPathDir="'shop/pic/'"
              style="margin-bottom: 10px"
              :disabled="isLook"
            >
            </ossFileUpload>
            <div class="small-tip warning" v-if="!isLook">
              <span
                >请上传完税凭证；。格式要求：上传pdf或图片，且不超过10M，最多上传5个。上传多个文件时，将按文件上传的顺序给业务员展示资料</span
              >
            </div>
          </div>
        </div>
        <div class="row mutlti big">
          <div class="tip">其他上传材料</div>
          <div class="value input">
            <ossFileUpload
              key="3"
              ref="refOtherFileUpload"
              v-model="otherFiles"
              :limit="5"
              :accept="picFileExt"
              :maxFileLength="10"
              :ossUpload="true"
              :ossPathDir="'shop/pic/'"
              style="margin-bottom: 10px"
              :disabled="isLook"
            >
            </ossFileUpload>
            <div class="small-tip warning" v-if="!isLook">
              <span
                >请上传其他材料。格式要求：上传pdf或图片，且不超过10M，最多上传5个。上传多个文件时，将按文件上传的顺序给业务员展示资料</span
              >
            </div>
          </div>
        </div>
      </div>
      <OpsCheck
        :rejectHistory="csoMemberAuditLogDetailVOS || []"
        auditStatusPass="1"
        auditRejectKey="rejectReason"
        auditStatusKey="auditStatus"
        createName="createBy"
        createTime="createTime"
      ></OpsCheck>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="close">取 消</el-button>
        <el-button
          v-if="!isLook"
          size="small"
          type="primary"
          @click="handleSave"
          >保存并提交</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { contractEdit } from "@/api/compliance/contractManager";
import OpsCheck from "../components/ops-check.vue";
import {
  csoMemberAdd,
  csoMemberEdit,
  csoMemberDetail,
  idCardOcr,
} from "@/api/complianceCso/salesman";
import ossFileUpload from "@/components/ossFileUpload";
// import { getOssSrcFileName } from "@/utils/oss";
export default {
  components: {
    ossFileUpload,
    OpsCheck,
  },
  data() {
    return {
      action: 1, //1 新增  2 编辑 3 查看
      dialogVisible: false,
      mainId: null, // 合同ID
      loading: false,
      formData: {
        name: "",
        userTel: "",
        idCard: "",
        type: "0",
      }, // 合同详情
      csoMemberAuditLogDetailVOS: [], // 审核日志
      memberInfo: null,
      userFiles: [], // 身份证列表
      contractFiles: [], // 签约信息列表
      employeeFiles: [], // 雇佣凭证列表
      otherFiles: [], // 其他材料
      picFileExt: ".jpg,.png,.pdf,.jpeg",
    };
  },
  computed: {
    title() {
      let txt = "业务员";
      if (this.action === 2) {
        txt = `编辑${txt}`;
      } else if (this.action === 3) {
        txt = `查看${txt}`;
      } else {
        txt = `新增${txt}`;
      }
      return txt;
    },
    isLook() {
      return this.action === 3;
    },
  },
  watch: {
    userFiles: {
      handler(val) {
        let files = val.map((li) => this.getUploadFileUrl(li, 1));
        const allHaveAttachUrl = files.every((li) => li.attachUrl);
        if (allHaveAttachUrl) {
          this.getIdCardInfo(files.map((li) => li.attachUrl));
        }
        // attachName,attachType,attachUrl
      },
      deep: true,
    },
  },
  methods: {
    // 身份证 ocr 识别姓名和身份证号
    async getIdCardInfo(imgUrls) {
      if (imgUrls) {
        // 循环上传图片，识别身份证信息
        for (let i = 0; i < imgUrls.length; i++) {
          const imgUrl = imgUrls[i];
          const res = await idCardOcr({ url: imgUrl });
          if (res) {
            const { name, idNumber } = res;
            this.formData.name = name;
            this.formData.idCard = idNumber;
          }
        }
      }
    },
    open(options = null) {
      if (options) {
        const { action, row } = options;
        this.action = action || 1;
        if (row && row.id) {
          this.mainId = row.id;
          this.getDetail();
        }
      }
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.refUserFileUpload.init(); //初始化oss上传
        this.$refs.refContractFileUpload.init(); //初始化oss上传
        this.$refs.refOtherFileUpload.init(); //初始化oss上传
        this.$refs.refEmployeeFileUpload.init(); //初始化oss上传
      });
    },
    close() {
      this.dialogVisible = false;
    },
    onClosed() {
      this.reset();
    },
    reset() {
      this.action = 1;
      this.mainId = "";
      this.formData = {
        name: "",
        userTel: "",
        idCard: "",
        type: "0",
      };
      this.memberInfo = null;
      this.userFiles = [];
      this.contractFiles = [];
      this.employeeFiles = [];
      this.otherFiles = [];
      this.loading = false;
    },
    getDetail() {
      if (this.mainId) {
        this.loading = true;
        // 替换新的获取详情方法
        csoMemberDetail(this.mainId).then((data) => {
          this.loading = false;
          if (data) {
            this.formData = {
              name: data.name || "",
              userTel: data.userTel || "",
              idCard: data.idCard || "",
              type: data.type ? data.type.toString() : "0",
            };
            // let filesData = data.csoMemberAttachVOS;
            let filesData = data.csoMemberAttachAddQOS;
            this.csoMemberAuditLogDetailVOS =
              data.csoMemberAuditLogDetailVOS || [];
            if (filesData && filesData.length > 0) {
              this.userFiles = filesData
                .filter((li) => li.attachType == 1)
                .map((li) => {
                  return this.parseToUploadUrl(li);
                });
              this.contractFiles = filesData
                .filter((li) => li.attachType == 2)
                .map((li) => {
                  return this.parseToUploadUrl(li);
                });
              this.otherFiles = filesData
                .filter((li) => li.attachType == 3)
                .map((li) => {
                  return this.parseToUploadUrl(li);
                });
              this.employeeFiles = filesData
                .filter((li) => li.attachType == 4)
                .map((li) => {
                  return this.parseToUploadUrl(li);
                });
            }
          }
        });
      }
    },
    handleSave() {
      const {
        mainId,
        formData,
        userFiles,
        contractFiles,
        otherFiles,
        employeeFiles,
      } = this;
      const { name, userTel, idCard } = this.formData;
      if (!name) {
        this.$message.warning("业务员姓名不能为空");
        return;
      }
      if (!idCard) {
        this.$message.warning("业务员身份证号不能为空");
        return;
      }
      if (!userTel) {
        this.$message.warning("业务员账号不能为空");
        return;
      }
      let telReg = /^1[3456789]\d{9}$/;
      if (!telReg.test(userTel)) {
        this.$message.warning("业务员账号必须为手机号");
        return;
      }
      if (userFiles.length == 0) {
        this.$message.warning("业务员身份信息文件不能为空");
        return;
      }
      if (this.formData.type === "0" && contractFiles.length == 0) {
        this.$message.warning("签约信息文件不能为空");
        return;
      }
      if (this.formData.type === "1" && employeeFiles.length == 0) {
        this.$message.warning("雇佣凭证文件不能为空");
        return;
      }
      // if(otherFiles.length == 0){
      //   this.$message.warning('其他材料文件不能为空');return;
      // }
      let files_a = userFiles.map((li) => this.getUploadFileUrl(li, 1));
      let files_b = contractFiles.map((li) => this.getUploadFileUrl(li, 2));
      let files_c = otherFiles.map((li) => this.getUploadFileUrl(li, 3));
      let files_d = employeeFiles.map((li) => this.getUploadFileUrl(li, 4));
      const data = {
        ...formData,
        csoMemberAttachAddQOS: [...files_a, ...files_b, ...files_c, ...files_d],
      };
      console.log(data);

      this.loading = true;

      if (mainId) {
        data.id = mainId;
        //编辑
        csoMemberEdit(data)
          .then((res) => {
            if (res) {
              this.$emit("onRefresh");
              this.dialogVisible = false;
            }
            this.loading = false;
          })
          .catch((ex) => {
            this.loading = false;
          });
      } else {
        //保存
        csoMemberAdd(data)
          .then((res) => {
            if (res) {
              this.$emit("onRefresh");
              this.dialogVisible = false;
            }
            this.loading = false;
          })
          .catch((ex) => {
            this.loading = false;
          });
      }
    },
    getUploadFileUrl(item, type = 1) {
      let netUrl = "";
      if (item) {
        if (item.url) {
          //可能是已经上传过的
          netUrl = item.url;
        } else if (
          //服务器返回过来的
          item.response &&
          item.response.data &&
          item.response.data.url
        ) {
          netUrl = item.response.data.url;
        }
      }
      // attachType 1 身份证 2 签约信息 3 其他材料 4 外部业务员：雇佣凭证
      return { attachName: item.name, attachUrl: netUrl, attachType: type };
    },
    parseToUploadUrl(el) {
      const { attachUrl, attachName } = el;
      // attachUrl 取最后一个.后面的字符串 作为文件类型
      if (attachUrl) {
        const reg = /(\.\w+)$/;
        const type = reg.exec(attachUrl)[1];
        // return { name: attachName, url: attachUrl, type };
        const paddName = type ? attachName + type : attachName;
        return { name: paddName, url: attachUrl };
      }
      return { name: attachName, url: "" };
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-root {
  .form-content {
    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 0;
      box-sizing: border-box;
      &.mutlti {
        align-items: flex-start;
      }
      &.big {
        .tip {
          height: 32px;
          line-height: 32px;
        }
      }
      .tip {
        width: 8em;
        text-align: right;
        font-size: 14px;
        font-weight: 400;
        text-align: right;
        color: #262626;
        span.point {
          color: #f00;
          margin-right: 2px;
        }
      }
      .value {
        margin-left: 16px;
        &-desc {
          line-height: 1;
          margin-top: 6px;
          color: #8c8c8c;
          font-size: 13px;
        }
        &.input {
          width: calc(100% - 200px);
        }
        &.fill {
          flex: 1;
          width: 0;
        }
        .small-tip {
          font-size: 14px;
          font-weight: 400;
          text-align: left;
          color: #8c8c8c;
          &.warning {
            color: #e6a23c;
          }
        }
        &.mutlti {
          flex: 1;
          width: 0;
          display: flex;
        }
      }
    }
  }
  ::v-deep .el-dialog__body {
    max-height: 60vh;
    overflow-y: auto;
    min-height: 240px;
  }
}
</style>
