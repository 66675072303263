<template>
  <div class="classification">
    <div class="header">
      <el-form
        ref="formTool"
        :model="formTool"
        :inline="true"
        @submit.native.prevent
        style="text-align: left"
      >
        <el-form-item label="">
          <el-input
            style="width: 150px"
            v-model="formTool.memberName"
            placeholder="搜索提交人"
            clearable
            size="small"
          />
        </el-form-item>
        <el-form-item prop="status" label="">
          <el-select
            v-model="formTool.status"
            placeholder="筛选状态"
            size="small"
            style="width: 150px"
            clearable
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="(item, index) in statusArr"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="getList(1)"
            >查询</el-button
          >
          <el-button size="small" @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btn-group">
        <el-button size="small" type="primary" @click="handleCreate"
          >新建</el-button
        >
      </div>
    </div>
    <div class="center" style="position: relative">
      <MyTable
        :index="false"
        :table-data="tableData"
        :page="page"
        :table-option.sync="tableOption"
        @page-change="tablePageChange"
      >
        <template slot="status" slot-scope="scope">
          {{ statusMap[scope.row.status] }}
        </template>
        <template slot="tools" slot-scope="scope">
          <div class="table-op-col">
            <el-link
              type="primary"
              :underline="false"
              size="small"
              v-if="scope.row.status === 0 || scope.row.status === 1"
              @click="openDetail(2, scope.row)"
              >重新上传</el-link
            >
            <el-link
              type="primary"
              :underline="false"
              size="small"
              @click="openDetail(3, scope.row)"
              >查看</el-link
            >
          </div>
        </template>
      </MyTable>
    </div>
    <ReceiptDetail
      ref="receiptDetailRef"
      @onRefresh="handleRefresh"
    ></ReceiptDetail>
  </div>
</template>

<script>
import MyTable from "@/components/myTable/index.vue";
import { csoDutyPaidProofSubClassPage } from "@/api/complianceCso/salesman";
import ReceiptDetail from "./components/receipt-detail.vue";
export default {
  components: {
    MyTable,
    ReceiptDetail,
  },
  data() {
    return {
      formTool: {
        memberName: "",
        status: "",
        memberId: "",
        targetTaskId: "",
      },
      statusMap: {
        0: "待审核",
        1: "审核驳回",
        2: "平台审核通过",
        3: "医药公司审核通过",
      },
      tableData: [],
      tableOption: [
        { label: "完税凭证金额", prop: "taskAmount" },
        { label: "提交人", prop: "submitMember" },
        { label: "提交时间", prop: "submitTime" },
        { label: "审核人", prop: "auditMember" },
        { label: "审核时间", prop: "auditTime" },
        { label: "状态", prop: "status", slot: true },
        { label: "操作", prop: "tools", slot: true, width: "180px" },
      ],
      page: { total: 0, current: 1, size: 10 },
    };
  },
  computed: {
    statusArr() {
      // 获取 statusMap 的 key 数组
      const kesyArr = Object.keys(this.statusMap);
      const valuesArr = Object.values(this.statusMap);
      const arr = [];
      for (let i = 0; i < kesyArr.length; i++) {
        arr.push({ label: valuesArr[i], value: kesyArr[i] });
      }
      return arr;
    },
  },
  mounted() {
    const query = this.$route.query;
    if (query.memberId && query.targetTaskId) {
      this.formTool.memberId = query.memberId;
      this.formTool.targetTaskId = query.targetTaskId;
      this.getList(1);
    }
  },
  methods: {
    handleCreate() {
      this.openDetail(1, {
        csoMemberId: this.formTool.memberId,
        targetTaskId: this.formTool.targetTaskId,
      });
    },
    openDetail(action = 3, row = null) {
      this.$refs.receiptDetailRef.open({ action, row });
    },
    getList(num) {
      if (num) {
        this.page.current = num;
      }
      const { current, size } = this.page;
      let params = { current, size, ...this.formTool };
      this.listLoading = true;
      csoDutyPaidProofSubClassPage(params)
        .then((res) => {
          this.tableData = res.records;
          this.page.total = res.total;
        })
        .catch(() => {})
        .finally(() => {
          this.listLoading = false;
        });
    },
    handleRefresh() {
      this.getList();
    },
    tablePageChange() {
      this.getList();
    },
    handleReset() {
      for (let key in this.formTool) {
        if (key !== "memberId" && key !== "targetTaskId") {
          this.formTool[key] = "";
        }
      }
      this.getList(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.classification {
  padding: 0px;
  background: white;

  .header {
    min-height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f5f5f5;
    .el-form-item {
      margin: 0px 0px 0px 8px !important;
    }
  }

  .center {
    margin-top: 16px;
    padding: 0 24px;
    height: calc(100% - 131px);
  }

  .table-op-col {
    display: flex;
    align-items: center;

    .el-link {
      & + .el-link {
        margin-left: 8px;
      }
    }
  }
  .btn-group {
    padding-right: 16px;
  }
}
</style>
