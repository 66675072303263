import { render, staticRenderFns } from "./ProductDetailDialog.vue?vue&type=template&id=1cb0b3f4&scoped=true"
import script from "./ProductDetailDialog.vue?vue&type=script&lang=js"
export * from "./ProductDetailDialog.vue?vue&type=script&lang=js"
import style0 from "./ProductDetailDialog.vue?vue&type=style&index=0&id=1cb0b3f4&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cb0b3f4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/godata/pipelines/eShop-Portal-Web-Prod/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1cb0b3f4')) {
      api.createRecord('1cb0b3f4', component.options)
    } else {
      api.reload('1cb0b3f4', component.options)
    }
    module.hot.accept("./ProductDetailDialog.vue?vue&type=template&id=1cb0b3f4&scoped=true", function () {
      api.rerender('1cb0b3f4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/publishProduct/dialog/ProductDetailDialog.vue"
export default component.exports