<!-- 医药公司 查看财务凭证列表 -->
<template>
  <div class="classification">
    <div class="header">
      <el-form
        ref="formTool"
        :model="formTool"
        :inline="true"
        style="text-align: left"
      >
        <el-form-item prop="contractName" label="">
          <el-input
            v-model="formTool.contractName"
            style="width: 150px"
            placeholder="搜索合同名称"
            clearable
            size="small"
          />
        </el-form-item>
        <el-form-item prop="contractCode" label="">
          <el-input
            v-model="formTool.contractCode"
            style="width: 150px"
            placeholder="搜索合同编码"
            clearable
            size="small"
          />
        </el-form-item>
        <el-form-item prop="medicalShopName" label="">
          <el-input
            v-model="formTool.medicalShopName"
            style="width: 150px"
            placeholder="搜索医药公司"
            clearable
            size="small"
          />
        </el-form-item>
        <el-form-item prop="targetName" label="">
          <el-input
            v-model="formTool.targetName"
            placeholder="搜索指标"
            style="width: 150px"
            clearable
            size="small"
          />
        </el-form-item>
        <el-form-item prop="voucherAuditStatus" label="">
          <el-select
            v-model="formTool.voucherAuditStatus"
            style="width: 150px"
            size="small"
            placeholder="请选择指标状态"
            clearable
          >
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="getList(1)"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="handleReset">重置</el-button>
        </el-form-item>
        <!-- <el-form-item>
            <el-button size="small" type="primary" @click="handleExport">导出</el-button>
          </el-form-item> -->
      </el-form>
      <!-- <el-button size="small" @click="openOpDetail">新增</el-button> -->
    </div>

    <div class="center" style="position: relative">
      <MyTable
        :index="false"
        :table-data="tableData"
        :page="page"
        :table-option.sync="tableOption"
        @page-change="getList"
      >
        <template slot="voucherAuditStatus" slot-scope="scope">
          <span>{{ status(scope.row.voucherAuditStatus) }}</span>
        </template>
        <template slot="operate" slot-scope="scope">
          <div class="table-op-col">
            <el-link
              v-if="scope.row.voucherAuditStatus === 1"
              type="primary"
              :underline="false"
              size="small"
              @click="handleUploadVoucher(scope.$index, 4)"
              >审核凭证</el-link
            >
            <el-link
              type="primary"
              :underline="false"
              size="small"
              v-if="scope.row.voucherAuditStatus > 0"
              @click="handleUploadVoucher(scope.$index, 3)"
              >查看凭证</el-link
            >
          </div>
        </template>
      </MyTable>
    </div>
    <opVoucherDlg ref="refOpVoucherDlg" @onRefresh="handleRefresh" />
  </div>
</template>

<script>
import MyTable from "@/components/myTable";
import opVoucherDlg from "@/views/compliance/financialManager/dialog/opVoucherDlg.vue";
import { contractTargetTaskAccessoryCsoPage } from "@/api/compliance/metricsManager";
import { financeHelp } from "@/utils/viewHelp";

export default {
  components: { MyTable, opVoucherDlg },
  data() {
    return {
      financeStatusValues: Object.keys(financeHelp.financeStatusList),
      formTool: {
        contractName: "",
        medicalShopName: "",
        targetName: "",
        voucherAuditStatus: "",
        contractCode: "",
      },
      tableData: [],
      tableOption: [
        { label: "合同名称", prop: "contractName" },
        { label: "合同编码", prop: "contractCode" },
        { label: "医药公司", prop: "draftShopName" },
        { label: "指标名称", prop: "targetName" },
        { label: "任务名称", prop: "targetTaskName" },
        { label: "任务金额", prop: "amount" },
        {
          label: "已审核发票金额",
          prop: "csoInvoicePrice",
        },
        { label: "已审核凭证金额", prop: "voucherPrice" },
        { label: "审核状态", prop: "voucherAuditStatus", slot: true },
        { label: "操作", prop: "operate", slot: true, width: "200px" },
      ],
      page: { total: 0, current: 1, size: 10 },
    };
  },
  created() {
    this.getList(1);
  },
  computed: {
    statusList() {
      let list = [];
      for (let key in financeHelp.financeStatusList) {
        list.push({ value: key, label: financeHelp.financeStatusList[key] });
      }

      return list;
    },
  },
  methods: {
    status(voucherAuditStatus) {
      return financeHelp.status(voucherAuditStatus);
    },
    getList(num) {
      if (num) {
        this.page.current = num;
      }
      const { current, size } = this.page;
      let params = { current, size, ...this.formTool };
      this.listLoading = true;
      contractTargetTaskAccessoryCsoPage(params)
        .then((res) => {
          this.tableData = res.records;
          this.page.total = res.total;
          this.listLoading = false;
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    handleRefresh() {
      this.getList(1);
    },
    handleReset() {
      for (let key in this.formTool) {
        this.formTool[key] = "";
      }
      this.getList(1);
    },
    handleUploadVoucher(index, action) {
      const row = this.tableData[index];
      this.openOpVoucher({ action, id: row.id });
    },
    openOpVoucher(options) {
      this.$refs.refOpVoucherDlg.open(options);
    },
  },
};
</script>

<style lang="scss" scoped>
.classification {
  padding: 0px;

  .header {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 24px 0px 24px !important;
    border-bottom: 1px solid #f5f5f5;

    .font {
      font-size: 16px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 600;
      text-align: left;
      color: #262626;
    }

    .el-form-item {
      margin: 0px 0px 0px 8px !important;
    }
  }

  background: white;

  .center {
    margin-top: 16px;
    padding: 0 24px;
    height: calc(100% - 131px);

    .shangjia {
      position: absolute;
      z-index: 2;
      bottom: -45px;
    }
  }

  .btn-warp {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .el-link {
      // margin: 5px;
    }
  }

  .table-op-col {
    display: flex;
    .el-link {
      & + .el-link {
        margin-left: 8px;
      }
    }
  }
}
</style>
