var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detail-root" },
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            title: _vm.title,
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            width: "918px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            closed: _vm.onClosed,
          },
        },
        [
          _c("div", { staticClass: "content form-content" }, [
            _c("div", { staticClass: "row mutlti big" }, [
              _c("div", { staticClass: "tip" }, [
                _c("span", { staticClass: "point" }, [_vm._v("*")]),
                _vm._v("业务员类型"),
              ]),
              _c(
                "div",
                {
                  staticClass: "value input",
                  staticStyle: { "margin-top": "8px" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formData.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "type", $$v)
                        },
                        expression: "formData.type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "0" } }, [
                        _vm._v("内部业务员"),
                      ]),
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("第三方业务员"),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "value-desc" }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row mutlti big" }, [
              _c("div", { staticClass: "tip" }, [
                _c("span", { staticClass: "point" }, [_vm._v("*")]),
                _vm._v("业务员身份信息"),
              ]),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("ossFileUpload", {
                    key: "1",
                    ref: "refUserFileUpload",
                    staticStyle: { "margin-bottom": "10px" },
                    attrs: {
                      limit: 3,
                      accept: _vm.picFileExt,
                      maxFileLength: 10,
                      ossUpload: true,
                      ossPathDir: "shop/pic/",
                      disabled: _vm.isLook,
                    },
                    model: {
                      value: _vm.userFiles,
                      callback: function ($$v) {
                        _vm.userFiles = $$v
                      },
                      expression: "userFiles",
                    },
                  }),
                  !_vm.isLook
                    ? _c("div", { staticClass: "small-tip warning" }, [
                        _c("span", [
                          _vm._v(
                            "请按顺序上传业务员身份证正面/反面。格式要求：pdf或图片，且不超过10M，最多上传3个。上传多个文件时，将按文件上传的顺序给业务员展示资料"
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row mutlti big" }, [
              _c("div", { staticClass: "tip" }, [
                _c("span", { staticClass: "point" }, [_vm._v("*")]),
                _vm._v("业务员姓名"),
              ]),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      maxlength: "20",
                      placeholder: "请输入姓名",
                    },
                    model: {
                      value: _vm.formData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row mutlti big" }, [
              _c("div", { staticClass: "tip" }, [
                _c("span", { staticClass: "point" }, [_vm._v("*")]),
                _vm._v("业务员身份证"),
              ]),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      maxlength: "20",
                      placeholder: "请输入",
                    },
                    model: {
                      value: _vm.formData.idCard,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "idCard", $$v)
                      },
                      expression: "formData.idCard",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row mutlti big" }, [
              _c("div", { staticClass: "tip" }, [
                _c("span", { staticClass: "point" }, [_vm._v("*")]),
                _vm._v("业务员账号"),
              ]),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      maxlength: "20",
                      disabled: _vm.action === 2,
                      placeholder: "请输入账号",
                    },
                    model: {
                      value: _vm.formData.userTel,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "userTel", $$v)
                      },
                      expression: "formData.userTel",
                    },
                  }),
                  _c("div", { staticClass: "value-desc" }, [
                    _vm._v(" 请输入手机号，手机号将作为业务员登录系统的账号 "),
                  ]),
                ],
                1
              ),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.formData.type === "0",
                    expression: "formData.type === '0'",
                  },
                ],
                staticClass: "row mutlti big",
              },
              [
                _c("div", { staticClass: "tip" }, [
                  _c("span", { staticClass: "point" }, [_vm._v("*")]),
                  _vm._v("业务员签约信息"),
                ]),
                _c(
                  "div",
                  { staticClass: "value input" },
                  [
                    _c("ossFileUpload", {
                      key: "2",
                      ref: "refContractFileUpload",
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: {
                        limit: 5,
                        accept: _vm.picFileExt,
                        maxFileLength: 10,
                        ossUpload: true,
                        ossPathDir: "shop/pic/",
                        disabled: _vm.isLook,
                      },
                      model: {
                        value: _vm.contractFiles,
                        callback: function ($$v) {
                          _vm.contractFiles = $$v
                        },
                        expression: "contractFiles",
                      },
                    }),
                    !_vm.isLook
                      ? _c("div", { staticClass: "small-tip warning" }, [
                          _c("span", [
                            _vm._v(
                              "请上传业务员合同信息。格式要求：上传pdf或图片，且不超过10M，最多上传5个。上传多个文件时，将按文件上传的顺序给业务员展示资料"
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.formData.type === "1",
                    expression: "formData.type === '1'",
                  },
                ],
                staticClass: "row mutlti big",
              },
              [
                _c("div", { staticClass: "tip" }, [
                  _c("span", { staticClass: "point" }, [_vm._v("*")]),
                  _vm._v(" 雇佣凭证"),
                ]),
                _c(
                  "div",
                  { staticClass: "value input" },
                  [
                    _c("ossFileUpload", {
                      key: "4",
                      ref: "refEmployeeFileUpload",
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: {
                        limit: 5,
                        accept: _vm.picFileExt,
                        maxFileLength: 10,
                        ossUpload: true,
                        ossPathDir: "shop/pic/",
                        disabled: _vm.isLook,
                      },
                      model: {
                        value: _vm.employeeFiles,
                        callback: function ($$v) {
                          _vm.employeeFiles = $$v
                        },
                        expression: "employeeFiles",
                      },
                    }),
                    !_vm.isLook
                      ? _c("div", { staticClass: "small-tip warning" }, [
                          _c("span", [
                            _vm._v(
                              "请上传完税凭证；。格式要求：上传pdf或图片，且不超过10M，最多上传5个。上传多个文件时，将按文件上传的顺序给业务员展示资料"
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
            _c("div", { staticClass: "row mutlti big" }, [
              _c("div", { staticClass: "tip" }, [_vm._v("其他上传材料")]),
              _c(
                "div",
                { staticClass: "value input" },
                [
                  _c("ossFileUpload", {
                    key: "3",
                    ref: "refOtherFileUpload",
                    staticStyle: { "margin-bottom": "10px" },
                    attrs: {
                      limit: 5,
                      accept: _vm.picFileExt,
                      maxFileLength: 10,
                      ossUpload: true,
                      ossPathDir: "shop/pic/",
                      disabled: _vm.isLook,
                    },
                    model: {
                      value: _vm.otherFiles,
                      callback: function ($$v) {
                        _vm.otherFiles = $$v
                      },
                      expression: "otherFiles",
                    },
                  }),
                  !_vm.isLook
                    ? _c("div", { staticClass: "small-tip warning" }, [
                        _c("span", [
                          _vm._v(
                            "请上传其他材料。格式要求：上传pdf或图片，且不超过10M，最多上传5个。上传多个文件时，将按文件上传的顺序给业务员展示资料"
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
          _c("OpsCheck", {
            attrs: {
              rejectHistory: _vm.csoMemberAuditLogDetailVOS || [],
              auditStatusPass: "1",
              auditRejectKey: "rejectReason",
              auditStatusKey: "auditStatus",
              createName: "createBy",
              createTime: "createTime",
            },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.close } },
                [_vm._v("取 消")]
              ),
              !_vm.isLook
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.handleSave },
                    },
                    [_vm._v("保存并提交")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }