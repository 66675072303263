<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="500px"
    append-to-body
    @close="handleClose"
  >
    <ossFileUpload
      v-model="list"
      :ossUpload="true"
      :maxFileLength="10"
      :limit="3"
      ref="taskFileUpload"
    />
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleSubmit">确定</el-button>
      <el-button @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import ossFileUpload from "@/components/ossFileUpload";
import { parseToUploadUrl } from "@/utils/oss";
export default {
  components: {
    ossFileUpload,
  },
  name: "taskFileDlg",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogVisible: false,
      list: [],
      picFileExt: " .png, .jpg, .pdf,.jpeg",
    };
  },
  methods: {
    publicOpen() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.taskFileUpload.init(); //初始化oss上传
      });
    },
    handleClose() {
      this.$refs.refPicFileUpload?.release();
    },
    close() {
      this.dialogVisible = false;
    },
    handleSubmit() {
      const urls = this.list.map((item) => {
        return this.getUploadFileUrl(item);
      });
      this.$emit("submit", urls);
      this.list = [];
      this.dialogVisible = false;
    },
    getUploadFileUrl(item) {
      let netUrl = "";
      if (item) {
        if (item.response && item.response.data && item.response.data.url) {
          netUrl = item.response.data.url;
        }
        if (!netUrl && item.url) {
          //可能是已经上传过的
          netUrl = item.url;
        }
      }
      return netUrl;
    },
  },
};
</script>
<style scoped lang="scss"></style>
