var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detail-root" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "审核发票附件",
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            closed: _vm.onClosed,
          },
        },
        [
          _c("div", { staticClass: "content form-content" }, [
            _c("div", { staticClass: "row mutlti big" }, [
              _c("div", { staticClass: "tip" }, [_vm._v("发票金额")]),
              _c(
                "div",
                { staticClass: "value input radio" },
                [
                  _c("el-input", {
                    staticStyle: { width: "250px" },
                    attrs: { disabled: true },
                    model: {
                      value: _vm.formData.amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "amount", $$v)
                      },
                      expression: "formData.amount",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row mutlti big" }, [
              _c("div", { staticClass: "tip" }, [_vm._v("附件")]),
              _c(
                "div",
                { staticClass: "value input radio" },
                [
                  _c("ossFileUpload", {
                    ref: "refPicFileUpload",
                    staticStyle: { "margin-bottom": "10px" },
                    attrs: {
                      limit: 1,
                      accept: _vm.picFileExt,
                      maxFileLength: 10,
                      ossUpload: true,
                      ossPathDir: "shop/pic/",
                      disabled: true,
                      listType: "text",
                    },
                    model: {
                      value: _vm.formData.picFileList,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "picFileList", $$v)
                      },
                      expression: "formData.picFileList",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row mutlti big" }, [
              _c("div", { staticClass: "tip" }, [_vm._v("审核结果")]),
              _c(
                "div",
                { staticClass: "value input radio" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formData.radio,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "radio", $$v)
                        },
                        expression: "formData.radio",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("通过")]),
                      _c("el-radio", { attrs: { label: 3 } }, [_vm._v("驳回")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm.formData.radio == 3
              ? _c("div", { staticClass: "row mutlti big" }, [
                  _c("div", { staticClass: "tip" }, [_vm._v("驳回理由")]),
                  _c(
                    "div",
                    { staticClass: "value input" },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "small",
                          type: "textarea",
                          maxlength: "20",
                          placeholder: "请输入驳回理由",
                          autosize: { minRows: 2, maxRows: 4 },
                        },
                        model: {
                          value: _vm.formData.desc,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "desc", $$v)
                          },
                          expression: "formData.desc",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "row mutlti big" }, [
              _c("div", { staticClass: "tip" }, [_vm._v("审核历史")]),
              _c(
                "div",
                {
                  staticClass: "value input",
                  staticStyle: { "margin-top": "8px" },
                },
                [
                  _c(
                    "el-timeline",
                    { attrs: { reverse: false } },
                    _vm._l(_vm.rejectHistory, function (activity, index) {
                      return _c(
                        "el-timeline-item",
                        {
                          key: index,
                          attrs: { timestamp: activity.createTime },
                        },
                        [
                          _c("div", [
                            _c("span", [
                              _vm._v("【" + _vm._s(activity.createBy) + "】"),
                            ]),
                            _c("span", [
                              _vm._v(
                                _vm._s(activity.state === 1 ? "通过" : "驳回：")
                              ),
                            ]),
                            _c("span", [_vm._v(_vm._s(activity.reason || ""))]),
                          ]),
                        ]
                      )
                    }),
                    1
                  ),
                  _c("div", { staticClass: "value-desc" }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.close } },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleSave },
                },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }