<template>
  <div>
    <!-- 审核历史 -->
    <div
      class="row mutlti big"
      v-if="rejectHistory && rejectHistory.length > 0"
    >
      <div class="tip"><span class="point">*</span>审核历史</div>
      <div class="value input" style="margin-top: 8px">
        <el-timeline :reverse="false">
          <el-timeline-item
            v-for="(activity, index) in rejectHistory"
            :key="index"
            :timestamp="activity[createTime]"
          >
            <div>
              {{ "【" + activity[createName] + "】" }}
              {{
                auditStatusPass.toString().indexOf(activity[auditStatusKey]) >
                -1
                  ? "通过"
                  : "驳回："
              }}
              {{ activity[auditRejectKey] || "" }}
            </div>
          </el-timeline-item>
        </el-timeline>
        <div class="value-desc"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ops-check",
  props: {
    rejectHistory: {
      type: Array,
      default: () => [],
    },
    auditStatusPass: {
      type: Number | String,
      default: 1,
    },
    auditStatusKey: {
      type: String,
      default: "auditStatus",
    },
    auditRejectKey: {
      type: String,
      default: "refuseReason",
    },
    createName: {
      type: String,
      default: "createName",
    },
    createTime: {
      type: String,
      default: "createTime",
    },
  },
  data() {
    return {
      formData: {},
    };
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 0;
  box-sizing: border-box;
  &.mutlti {
    align-items: flex-start;
  }
  &.big {
    .tip {
      height: 32px;
      line-height: 32px;
    }
  }
  .tip {
    width: 8em;
    text-align: right;
    font-size: 14px;
    font-weight: 400;
    text-align: right;
    color: #262626;
    span.point {
      color: #f00;
      margin-right: 2px;
    }
  }
  .value {
    margin-left: 16px;
    &-desc {
      line-height: 1;
      margin-top: 6px;
      color: #8c8c8c;
      font-size: 13px;
    }
    &.input {
      width: calc(100% - 200px);
    }
    &.fill {
      flex: 1;
      width: 0;
    }
    .small-tip {
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      color: #8c8c8c;
      &.warning {
        color: #e6a23c;
      }
    }
    &.mutlti {
      flex: 1;
      width: 0;
      display: flex;
    }
  }
}
</style>
