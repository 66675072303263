var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "classification" },
    [
      _c("div", { staticClass: "metrics-infos" }, [
        _c("div", { staticClass: "sigle-info" }, [
          _c("span", { staticClass: "tip" }, [_vm._v("任务名称：")]),
          _c("span", [_vm._v(_vm._s(_vm.task ? _vm.task.name : ""))]),
        ]),
        _c("div", { staticClass: "sigle-info" }, [
          _c("span", { staticClass: "tip" }, [_vm._v("指标名称：")]),
          _c("span", [_vm._v(_vm._s(_vm.task ? _vm.task.targetName : ""))]),
        ]),
        _c("div", { staticClass: "sigle-info" }, [
          _c("span", { staticClass: "tip" }, [_vm._v("合同名称：")]),
          _c("span", [_vm._v(_vm._s(_vm.task ? _vm.task.contractName : ""))]),
        ]),
        _c("div", { staticClass: "sigle-info" }, [
          _c("span", { staticClass: "tip" }, [_vm._v("医药公司名称：")]),
          _c("span", [_vm._v(_vm._s(_vm.task ? _vm.task.draftShopName : ""))]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "header" },
        [
          _c(
            "el-form",
            {
              ref: "formTool",
              staticStyle: { "text-align": "left" },
              attrs: { model: _vm.formTool, inline: true },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "csoMemberName", label: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: {
                      placeholder: "搜索业务员",
                      clearable: "",
                      size: "small",
                    },
                    model: {
                      value: _vm.formTool.csoMemberName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formTool, "csoMemberName", $$v)
                      },
                      expression: "formTool.csoMemberName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "sumitTime", label: "提交时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      size: "small",
                      type: "daterange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.formTool.sumitTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.formTool, "sumitTime", $$v)
                      },
                      expression: "formTool.sumitTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "taskStatus", label: "" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "150px" },
                      attrs: {
                        size: "small",
                        placeholder: "筛选状态",
                        clearable: "",
                      },
                      model: {
                        value: _vm.formTool.taskStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.formTool, "taskStatus", $$v)
                        },
                        expression: "formTool.taskStatus",
                      },
                    },
                    _vm._l(_vm.auditStatusList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "createBy", label: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: {
                      placeholder: "搜索分派人",
                      clearable: "",
                      size: "small",
                    },
                    model: {
                      value: _vm.formTool.createBy,
                      callback: function ($$v) {
                        _vm.$set(_vm.formTool, "createBy", $$v)
                      },
                      expression: "formTool.createBy",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.getList(1)
                        },
                      },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: { click: _vm.handleReset },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.handleBack } },
                [_vm._v("返回")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleAllocation },
                },
                [_vm._v("任务分派")]
              ),
              _vm.showBatch
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: { click: _vm.handleBatchCheck },
                    },
                    [_vm._v(" 批量审核")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "center", staticStyle: { position: "relative" } },
        [
          _c("MyTable", {
            attrs: {
              index: false,
              selection: true,
              "table-data": _vm.tableData,
              page: _vm.page,
              "table-option": _vm.tableOption,
            },
            on: {
              "update:tableOption": function ($event) {
                _vm.tableOption = $event
              },
              "update:table-option": function ($event) {
                _vm.tableOption = $event
              },
              handleSelectionChange: _vm.handleSelectionChange,
              "page-change": _vm.getList,
            },
            scopedSlots: _vm._u([
              {
                key: "taskStatus",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.status(scope.row.taskStatus))),
                    ]),
                  ]
                },
              },
              {
                key: "operate",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "table-op-col" },
                      [
                        _c(
                          "el-link",
                          {
                            attrs: {
                              type: "primary",
                              underline: false,
                              size: "small",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleLook(scope.$index)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                        scope.row.taskStatus === 8
                          ? _c(
                              "el-link",
                              {
                                attrs: {
                                  type: "primary",
                                  underline: false,
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRevocation(scope.$index)
                                  },
                                },
                              },
                              [_vm._v("撤回")]
                            )
                          : _vm._e(),
                        scope.row.taskStatus === 1
                          ? _c(
                              "el-link",
                              {
                                attrs: {
                                  type: "primary",
                                  underline: false,
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleCheck(scope.$index)
                                  },
                                },
                              },
                              [_vm._v("审核")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("childTaskDetailDlg", {
        ref: "refChildTaskDetailDlg",
        on: { onRefresh: _vm.handleRefresh },
      }),
      _c("batchCheckDlg", {
        ref: "refBatchCheckDlg",
        on: { onConfirm: _vm.handleConfirmCheck },
      }),
      _c("childtaskAllocation", {
        ref: "refChildtaskAllocation",
        on: { refresh: _vm.handleRefresh },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }