var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: _vm.title, visible: _vm.dialogVisible, width: "30%" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c("div", [
        _c(
          "div",
          { staticClass: "search-container" },
          [
            _c("div", { staticClass: "search-item" }, [_vm._v("区域：")]),
            _c("el-input", {
              attrs: { placeholder: "搜索区域", clearable: "", size: "small" },
              model: {
                value: _vm.name,
                callback: function ($$v) {
                  _vm.name = $$v
                },
                expression: "name",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "tree-container" },
          [
            _c("el-tree", {
              ref: "cityTree",
              attrs: {
                "show-checkbox": "",
                "node-key": "id",
                "default-expanded-keys": _vm.expandedKeys,
                "default-checked-keys": _vm.checkedKeys,
                data: _vm.regionTreedata,
                props: _vm.defaultProps,
                "filter-node-method": _vm.filterNode,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "dialog-footer",
            attrs: { slot: "footer" },
            slot: "footer",
          },
          [
            _c(
              "el-button",
              { attrs: { size: "small" }, on: { click: _vm.handleClose } },
              [_vm._v("关闭")]
            ),
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  size: "small",
                  loading: _vm.saveLoading,
                },
                on: { click: _vm.handleSave },
              },
              [_vm._v("保存")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }