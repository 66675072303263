import { render, staticRenderFns } from "./paidPayment.vue?vue&type=template&id=588871aa&scoped=true"
import script from "./paidPayment.vue?vue&type=script&lang=js"
export * from "./paidPayment.vue?vue&type=script&lang=js"
import style0 from "./paidPayment.vue?vue&type=style&index=0&id=588871aa&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "588871aa",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/godata/pipelines/eShop-Portal-Web-Prod/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('588871aa')) {
      api.createRecord('588871aa', component.options)
    } else {
      api.reload('588871aa', component.options)
    }
    module.hot.accept("./paidPayment.vue?vue&type=template&id=588871aa&scoped=true", function () {
      api.rerender('588871aa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/servicecenter/paidPayment.vue"
export default component.exports