<template>
  <div class="tabs-container">
    <el-tabs
      v-model="getActiveName"
      @tab-click="handleClick"
      :before-leave="beforeLeave"
      v-if="showTabs"
    >
      <el-tab-pane label="业务员管理" name="first"></el-tab-pane>
      <el-tab-pane label="人员资质管理" name="second"></el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  name: "CertifyAndSalesListTabs",
  props: {
    activeName: {
      type: String,
      default: "first",
    },
  },
  computed: {
    getActiveName: {
      get() {
        return this.activeName;
      },
      set(value) {
        this.$emit("update:activeName", value);
      },
    },
  },
  data() {
    return {
      showTabs: true,
    };
  },
  methods: {
    handleClick(tab, event) {},
    beforeLeave(el) {
      this.showTabs = false;
      if (el === "first") {
        //跳转到业务员管理页面
        this.$router.push({
          path: "compliancecso-salesman-list",
        });
      } else if (el === "second") {
        this.$router.push({
          path: "compliancecso-salesman-certifyList",
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.tabs-container {
  padding: 0px 30px;
}
</style>
