import request from "@/utils/request";
// import { Message } from 'element-ui'

// 分页查询CSO业务员
// export function getPage(data) {
//   return request({
//     url: "/shop/hg/csoMember/page",
//     method: "get",
//     params: data,
//   });
// }

// 医药公司查看待开发票
export function contractTargetPage(data) {
  return request({
    url: "/shop/hg/contractTarget/pageInvoice",
    method: "POST",
    data,
  });
}

// 医药公司审核CSO上传的发票
export function contractTargetAudit(data) {
  return request({
    url: "/shop/hg/contractTarget/auditInvoice",
    method: "POST",
    data,
  });
}

// 编辑CSO业务员
// export function csoMemberEdit(data) {
//   return request({
//     url: "/shop/hg/csoMember/edit",
//     method: "PATCH",
//     data
//   });
// }
// CSO公司查看待开发票列表
export function geCsotInvoiceList(data) {
  return request({
    url: "/shop/hg/contractTarget/pageCsoInvoice",
    method: "post",
    data,
  });
}
// 设置税率
export function setCSOTaxRate(data) {
  return request({
    url: "/invoice/fp/invoiceRate/editCsoRate",
    method: "post",
    data,
  });
}
// 查询 cso 设置的税率
export function getCsoTaxRate(data) {
  return request({
    url: "/invoice/fp/invoiceRate/csoRate",
    method: "get",
    params: data,
  });
}
//cso 设置收方信息 公司的抬头
export function csoEditInvoiceHeader(data) {
  return request({
    url: "/invoice/fp/invoiceHeader/csoEditInvoiceHeader",
    method: "post",
    data,
  });
}
// cso 上传发票 手动
export function csoUploadInvoice(data) {
  return request({
    url: "/shop/hg/contractTarget/csoUploadInvoice",
    method: "post",
    data,
  });
}
// 根据 id 查看发票
export function contractTargetInvoiceV2ById(id) {
  return request({
    url: `/shop/hg/contractTarget/invoice/v2/${id}`,
    method: "get",
  });
}
