<template>
  <el-dialog
    title="上传附件"
    :visible.sync="visible"
    :lock-scroll="true"
    width="618px"
    @close="resetForm"
  >
    <el-form label-width="100px">
      <el-form-item label="发票金额" required>
        <el-input v-model="amount" style="width: 250px"></el-input>
      </el-form-item>
      <el-form-item label="附件" required>
        <ossFileUpload
          ref="refPicFileUpload"
          v-model="picFileList"
          :limit="maxFileCount"
          :accept="picFileExt"
          :maxFileLength="10"
          :ossUpload="true"
          :ossPathDir="'shop/pic/'"
          style="margin-bottom: 10px"
          :disabled="disabled"
        >
        </ossFileUpload>
      </el-form-item>
    </el-form>

    <div class="dialog-footer">
      <el-button @click="handleClose" size="small">取消</el-button>
      <el-button type="primary" @click="handleSubmit" size="small"
        >确定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import ossFileUpload from "@/components/ossFileUpload";
import { csoUploadInvoice } from "@/api/compliance/invoiceManager";
export default {
  name: "UploadTaxFileDialog",
  components: {
    ossFileUpload,
  },
  data() {
    return {
      amount: "",
      picFileList: [],
      picFileExt: " .png, .jpg, .pdf,.jpeg",
      maxFileCount: 1,
      visible: false,
      disabled: false,
      id: "",
    };
  },
  methods: {
    resetForm() {
      this.amount = "";
      this.picFileList = [];
    },
    handleClose() {
      this.$emit("close");
      this.visible = false;
      this.id = "";
      this.picFileList = [];
    },
    async handleSubmit() {
      if (this.amount === "") {
        this.$message.warning("请填写发票金额");
        return;
      }
      if (this.picFileList.length === 0) {
        this.$message.warning("请上传附件");
        return;
      }
      let obj = {
        id: this.id,
        csoInvoiceUrl: "",
        csoInvoiceName: "",
        amount: this.amount,
      };
      this.picFileList.forEach((item) => {
        const response = item.response;
        if (response && response.data) {
          const { url, fileName } = response.data;
          obj.csoInvoiceUrl = url;
          obj.csoInvoiceName = fileName;
        }
      });
      const res = await csoUploadInvoice(obj);
      if (res) {
        this.$message.success("上传成功");
        this.$emit("onRefresh");
        this.handleClose();
      }
    },
    publicOpen(row) {
      this.visible = true;
      const { id } = row;
      this.id = id;
      this.$nextTick(() => {
        this.$refs.refPicFileUpload.init(); //初始化oss上传
      });
    },
  },
};
</script>

<style scoped>
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
