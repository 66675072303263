var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "child-task-detail-root" },
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "child-task-dialog",
          attrs: {
            title: _vm.title,
            "close-on-click-modal": false,
            visible: _vm.dialogVisible,
            width: "958px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            closed: _vm.onClosed,
          },
        },
        [
          _c("div", { staticClass: "full-content" }, [
            _c("div", { staticClass: "content form-content" }, [
              _c("div", { staticClass: "task-base-info" }, [
                _c("div", { staticClass: "base-info-item" }, [
                  _c("div", { staticClass: "tip" }, [_vm._v("编号")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.childtask ? _vm.childtask.code : "")),
                  ]),
                ]),
                _c("div", { staticClass: "base-info-item" }, [
                  _c("div", { staticClass: "tip" }, [_vm._v("流程状态")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.childtask
                            ? _vm.status(_vm.childtask.taskStatus)
                            : ""
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "base-info-item" }, [
                  _c("div", { staticClass: "tip" }, [_vm._v("提交人")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.childtask ? _vm.childtask.createBy : "")),
                  ]),
                ]),
                _c("div", { staticClass: "base-info-item" }, [
                  _c("div", { staticClass: "tip" }, [_vm._v("提交时间")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.childtask ? _vm.childtask.subTime : "") +
                        " "
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "block" }, [
                _c("div", { staticClass: "title" }, [_vm._v("任务基本信息")]),
                _c("div", { staticClass: "flex-layout" }, [
                  _c("div", { staticClass: "flex-item" }, [
                    _c("div", { staticClass: "tip" }, [
                      _vm._v("任务名称"),
                      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "value" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            disabled: "",
                            value: _vm.childtask ? _vm.childtask.taskName : "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "flex-item" }, [
                    _c("div", { staticClass: "tip" }, [
                      _vm._v("任务编码"),
                      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "value" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            disabled: "",
                            value: _vm.childtask ? _vm.childtask.taskCode : "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "flex-item" }, [
                    _c("div", { staticClass: "tip" }, [
                      _vm._v("指标名称"),
                      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "value" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            disabled: "",
                            value: _vm.childtask
                              ? _vm.childtask.targetName
                              : "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "flex-item" }, [
                    _c("div", { staticClass: "tip" }, [
                      _vm._v("CSO公司名称"),
                      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "value" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            disabled: "",
                            value: _vm.childtask
                              ? _vm.childtask.coopShopName
                              : "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "flex-item" }, [
                    _c("div", { staticClass: "tip" }, [
                      _vm._v("执行次数"),
                      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "value" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            disabled: "",
                            value: _vm.childtask ? _vm.childtask.taskNum : "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "flex-item" }, [
                    _c("div", { staticClass: "tip" }, [
                      _vm._v("单次执行金额"),
                      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "value" },
                      [
                        _c(
                          "el-input",
                          {
                            attrs: {
                              size: "small",
                              disabled: "",
                              value: _vm.childtask
                                ? _vm.childtask.taskPrice
                                : "",
                            },
                          },
                          [
                            _c("template", { slot: "prefix" }, [
                              _c("span", { staticClass: "unit-icon" }, [
                                _vm._v("￥"),
                              ]),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "flex-item" }, [
                    _c("div", { staticClass: "tip" }, [
                      _vm._v("任务类型"),
                      _c("span", { staticClass: "weight" }, [_vm._v("*")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "value" },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            disabled: "",
                            value: _vm.childtask
                              ? _vm.taskType(_vm.childtask.taskType)
                              : "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  0
                    ? _c("div", { staticClass: "flex-item" }, [
                        _c("div", { staticClass: "tip" }, [
                          _vm._v("截至日期"),
                          _c("span", { staticClass: "weight" }, [_vm._v("*")]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "value" },
                          [
                            _c("el-input", {
                              attrs: {
                                size: "small",
                                disabled: "",
                                value: _vm.childtask
                                  ? _vm.childtask.targetCode
                                  : "",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "flex-item long" }, [
                    _c("div", { staticClass: "tip" }, [_vm._v("任务说明")]),
                    _c("div", { staticClass: "value" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.childtask ? _vm.childtask.remark : "") +
                          " "
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "block" }, [
                _c("div", { staticClass: "title" }, [_vm._v("任务详情")]),
                _c(
                  "div",
                  { staticClass: "pre-child-tast" },
                  [
                    _c("childtemplate", {
                      ref: "childtemplateRef",
                      attrs: {
                        preview: true,
                        childTaskType: _vm.childtask
                          ? _vm.childtask.taskType
                          : -1,
                        childTaskDetail: _vm.childtask
                          ? _vm.childtask.taskDetail
                          : null,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm.reasonList.length > 0
                ? _c("div", { staticClass: "block rejected-layout" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("驳回记录")]),
                    _c(
                      "div",
                      { staticClass: "v-list" },
                      _vm._l(_vm.reasonList, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "v-list-item" },
                          [
                            _c("div", { staticClass: "left" }, [
                              _c("div", { staticClass: "line up" }),
                              _c("div", { staticClass: "dot" }),
                              _c("div", { staticClass: "line down" }),
                            ]),
                            _c("div", { staticClass: "datas-view" }, [
                              _c("div", { staticClass: "reason" }, [
                                _vm._v(_vm._s(item.refuseReason)),
                              ]),
                              _c("div", { staticClass: "time" }, [
                                _vm._v("驳回人：" + _vm._s(item.refuseUser)),
                              ]),
                              _c("div", { staticClass: "time" }, [
                                _vm._v(
                                  "驳回时间：" + _vm._s(item.rejectionTime)
                                ),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.close } },
                [_vm._v("关闭")]
              ),
              _vm.showAuditOpinion
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.handleCheckOk },
                    },
                    [_vm._v("通过")]
                  )
                : _vm._e(),
              _vm.showAuditOpinion
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.handleCheckFail },
                    },
                    [_vm._v("驳回")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c("rejustReason", {
        ref: "refRejustReason",
        on: { onConfirm: _vm.handleAuditFail },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }